import styled from "styled-components";

export const Container = styled.div`
  min-width: 20rem;
  width: 80%;
  max-width: 54rem;
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 2rem;
  border-radius: 4px;
  background-color: ${({theme})=> theme.colors.background};
`

export const Content = styled.div`
  width: 100%;
  margin-top: 1rem;
  border-radius: 4px;
  overflow: auto;
  height: 20rem;
  border: 1px solid ${({theme})=> theme.colors.primary};
  padding: 1rem;
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    &:hover{
      filter: brightness(0.9);
    }
  }
`

export const ListExternalFonts = styled.div`
  max-height: 10rem;
  overflow: auto;
  padding: 4px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`
