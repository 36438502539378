import styled from "styled-components";

interface Props {
  shown: boolean;
}

export const Container = styled.div<Props>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fffe;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: ${({ shown }) => (shown ? 9999 : -1)};
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  transition: all 0.3s ease-in-out;

  img {
    width: 100px;
    height: 100px;
    object-fit: scale-down;
  }

  p {
    opacity: 0;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s ease-in-out;
    color: ${({ theme }) => theme.colors.secondary};
  }

  p.shown {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  p:hover {
    text-decoration: underline;
  }
`;
