import { IEmailInputModel } from "inputModels";
import api from "services/api";
import { IEmailTypes } from "props";

const send = (input: IEmailInputModel) => 
  new Promise<string>((resolve, reject) => {
    api
      .post("email/send", input)
      .then((res) => resolve(res.data))
      .catch((error: any) => reject(error));
  });

const sendCodeVerification = (input: IEmailTypes) => 
    new Promise<string>((resolve, reject) => {
      api
        .post("email/send-code-email", input)
        .then((res) => resolve(res.data))
        .catch((error: any) => reject(error));
    });
export const EmailController = {
  send,
  sendCodeVerification
}