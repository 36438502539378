import ReactFlow, { getRectOfNodes, getTransformForBounds, useReactFlow } from "reactflow"
import { Container } from "./styled"
import { useRelationMap } from "contexts/relationMap"
import 'reactflow/dist/base.css';
import { useEffect } from "react";
import { toPng } from 'html-to-image';
import { useTheme } from "styled-components";
import { useApp } from "contexts";


export const ReactFlowMap = () => {

  const { nodes, edges, onEdgesChange, onNodesChange, onNodeDrag, center, imageName, setImageName } = useRelationMap()
  const { setCenter, getNodes } = useReactFlow()
  const { setLoading } = useApp()

  const zoom = 1.8
  const theme = useTheme()
  const imageWidth = 3840;
  const imageHeight = 2160;


  useEffect(()=>{
    if(imageName){
      setLoading(true)
      const nodesBounds = getRectOfNodes(getNodes());
      const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);
  
      toPng(document.querySelector('.react-flow__viewport') as HTMLElement, {
        backgroundColor: theme.colors.background,
        width: imageWidth,
        height: imageHeight,
        style: {
          width: imageWidth.toString(),
          height: imageHeight.toString(),
          transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
        },
      }).then((dataUrl) => {
        const a = document.createElement('a');
        a.setAttribute('download', imageName);
        a.setAttribute('href', dataUrl);
        a.click();
      })
      .finally(()=> setLoading(false))
      setImageName(null)
    }
  },[imageName])

  useEffect(()=>{
    setCenter(center.x,center.y,{zoom, duration: 1000})
  },[center])

  return (
    <Container id="mapContainer">
      {nodes &&
        <ReactFlow 
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDrag={(_,node) => onNodeDrag(node)}
          fitView
          className="download-image"
        >
        </ReactFlow>
      }
    </Container>
  )
}