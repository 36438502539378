import { Cancel } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Modal, Modals } from "components";
import { ModalProps } from "..";
import { useEffect, useState } from "react";
import { IUserViewModel } from "viewModels";
import { Controller, useForm } from "react-hook-form";
import { IUpdateCompanyUserInputModel } from "inputModels";
import { EmailController } from "controllers/EmailController";
import { validateEmail } from "utils/validateEmail";
import { usePersistedState } from "hooks";
import { decryptCode } from "utils/crypto";
import { useCompanyUser } from "contexts";
import { CompanyUserController } from "controllers";

interface IData {
  contactValue: string;
  contactLabel: string;
  codeExpected: string;
}

interface EditCompanyUserModalProps extends ModalProps {
  dataCode: IData;
  companyUser: IUserViewModel;
  onCodeChange: (newCode: string) => void;
}

const EditCompanyUserModal: React.FC<EditCompanyUserModalProps> = ({
  onCodeChange,
  companyUser,
  dataCode,
  ...props
}: EditCompanyUserModalProps) => {
  const TIMER = 60000;
  const { setCompanyUsers } = useCompanyUser();
  const [expectedCode, setExpectedCode] = useState("");
  const [isOpenRecoverPasswordModal, setIsOpenRecoverPasswordModal] =
    useState(false);
  const [selected, setSelected] = useState<IUserViewModel>(companyUser);
  const [requesting, setRequesting] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [editEmail, setEditEmail] = useState(false);
  const [dateToSend, setDateToSend] = usePersistedState<number | undefined>(
    "dateToSend",
    undefined
  );
  const [disableResend, setDisableResend] = useState(
    dateToSend === undefined ? false : dateToSend + TIMER > new Date().getTime()
  );
  const [secondsRemainingToResend, setSecondsRemainingToResend] = useState(
    TIMER / 1000
  );
  const [defaultValues] = useState({
    name: companyUser.name,
    email: companyUser.email,
    code: "",
    document: companyUser.document,
  });
  const {
    control,
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const code = watch("code");
  const data = watch();

  const verifyEmail = async () => {
    let hasError = false;
    const alreadyEmail = await CompanyUserController.verifyAlreadyEmail(
      data.email
    );
    if (alreadyEmail.data) {
      setError("email", { message: "Este email já existe" });
      hasError = true;
    }
    if (!validateEmail(data.email)) {
      setError("email", { message: "Email inválido" });
      hasError = true;
    }
    if (!data.email) {
      setError("email", { message: "Informe o email" });
      hasError = true;
    }
    if (!data.name) {
      setError("name", { message: "Informe o nome" });
      hasError = true;
    }
    if (hasError) {
      setRequesting(false);
      return;
    }
  };

  const handleUpdateCompanyUser = async () => {
    setRequesting(true);
    if (editEmail) {
      verifyEmail();
      setEditEmail(false);
    }
    const update: IUpdateCompanyUserInputModel = {
      id: companyUser.id,
      name: data.name,
      email: data.email,
    };
    if (data.name !== selected.name && data.email === selected.email) {
      CompanyUserController.updateUser(update)
        .then(() => {
          setCompanyUsers((prev) => {
            const index = prev.findIndex((m) => m.id === companyUser.id);
            prev[index] = {
              ...prev[index],
              name: data.name,
            };
            return [...prev];
          });
          setDateToSend(undefined);
          setDisableResend(false);
          props?.onClose();
        })
        .finally(() => {
          clearErrors();
          setValue("code", "");
        });
    }
    CompanyUserController.updateUser(update)
      .then(() => {
        setCompanyUsers((prev) => {
          const index = prev.findIndex((m) => m.id === companyUser.id);
          prev[index] = {
            ...prev[index],
            name: data.name,
            email: data.email,
          };
          return [...prev];
        });
        setRequesting(false);
        setDateToSend(undefined);
        setDisableResend(false);
        props?.onClose();
      })
      .finally(() => {
        setValue("code", "");
        setRequesting(false);
      });
  };

  const handleOnCallback = () => {
    if (!editEmail) return handleUpdateCompanyUser();
    if (dataCode.codeExpected === code && code !== "") {
      handleUpdateCompanyUser();
    } else {
      setError("code", {
        message: "Código não corresponde",
      });
    }
  };

  const handleEditEmail = () => {
    setEditEmail(!editEmail);
  };

  const handleSendConfirmationEmail = async () => {
    if (!companyUser?.id) return;
    setDateToSend(new Date().getTime());
    EmailController.sendCodeVerification({
      to: data.email,
      subject: "Edição de e-mail",
      description:
        "Utilize o código abaixo para editar seu email no Legal Control Audit:",
    }).then((resCode) => {
      onCodeChange(decryptCode(resCode));
      setDisabled(false);
    });
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (dateToSend !== undefined) {
      interval = setInterval(() => {
        const isDisabled = dateToSend + TIMER > new Date().getTime();
        setDisableResend(isDisabled);
        setSecondsRemainingToResend((prev) => prev - 1);
        if (!isDisabled) {
          setSecondsRemainingToResend(TIMER / 1000);
          setDateToSend(undefined);
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [dateToSend, setDateToSend]);

  useEffect(() => {
    setValue("name", companyUser.name);
    setValue("email", companyUser.email);
    setValue("document", companyUser.document);
    setSelected(companyUser);
  }, [companyUser, setValue]);

  return (
    <Modal
      {...props}
      sx={{
        position: "relative",
        width: "23rem",
        height: "34rem",
        overflowX: "hidden",
        overflowY: "auto",
        p: 1,
      }}
    >
      <IconButton
        size="small"
        onClick={props?.onClose}
        color="error"
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Cancel />
      </IconButton>

      <Container>
        <Grid>
          <Typography
            variant="h5"
            color="primary"
            marginTop={1}
            marginBottom={2}
          >
            Editar Empresa
          </Typography>
          <Grid marginBottom={2}>
            <div style={{ marginBottom: '15px' }}>
              <Controller 
                name= "document"
                control={control}
                render={({ field: { onChange, value, ...rest } }) => (
                  <TextField
                    fullWidth
                    value={value}
                    error={!!errors.document?.message}
                    helperText={errors.document?.message}
                    size="small"
                    disabled
                    label="Documento"
                    {...rest}
                  />
                )}
              />
            </div>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <TextField
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors("name");
                  }}
                  value={value}
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  size="small"
                  label="Nome"
                  {...rest}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginBottom={2}
          >
            <Button
              style={{ width: "12rem" }}
              type="button"
              variant="contained"
              onClick={handleEditEmail}
              sx={{ cursor: requesting ? "wait" : "pointer" }}
            >
              <Typography variant="button">Editar Email</Typography>
            </Button>
          </Grid>
          {editEmail && (
            <>
              <Grid marginBottom={2}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      onChange={(e) => {
                        onChange(e.target.value);
                        clearErrors("email");
                      }}
                      value={value ?? ""}
                      error={!!errors.email?.message}
                      helperText={errors.email?.message}
                      size="small"
                      label="E-mail"
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid display="flex" justifyContent="center" marginBottom={2}>
                <Button
                  style={{ width: "12rem" }}
                  disabled={disableResend}
                  type="submit"
                  variant="contained"
                  sx={{ cursor: requesting ? "wait" : "pointer" }}
                  onClick={() => {
                    handleSendConfirmationEmail();
                  }}
                >
                  <Typography variant="button">
                    {disableResend
                      ? `Enviar código (${secondsRemainingToResend})`
                      : "Enviar código"}
                  </Typography>
                </Button>
              </Grid>
              <Grid marginBottom={2}>
                <Controller
                  name="code"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      disabled={disabled}
                      onChange={onChange}
                      value={value}
                      size="small"
                      label="Código de Verificação"
                      helperText={errors.code?.message}
                      error={!!errors.code}
                      placeholder="Insira o código de verificação"
                      {...rest}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid
            display="flex"
            alignItems="center"
            gap="0.3rem"
            justifyContent="space-between"
            marginTop="12rem"
          >
            <Button
              style={{ width: "15rem" }}
              type="button"
              variant="contained"
              onClick={() => {
                setIsOpenRecoverPasswordModal(true);
              }}
              sx={{ cursor: requesting ? "wait" : "pointer" }}
            >
              <Typography variant="button">Recuperar Senha</Typography>
            </Button>
            <Button
              style={{ width: "11rem" }}
              type="button"
              variant="contained"
              onClick={handleSubmit(handleOnCallback)}
              sx={{ cursor: requesting ? "wait" : "pointer" }}
            >
              <Typography variant="button">Salvar</Typography>
              {requesting && (
                <CircularProgress size={15} sx={{ ml: 1, color: "white" }} />
              )}
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Modals.RecoverPasswordModal
        open={isOpenRecoverPasswordModal}
        typeModal="recoverPassword"
        onCodeChange={(codeChange) => setExpectedCode(codeChange)}
        data={{
          codeExpected: expectedCode,
          contactLabel: "E-mail",
          contactValue: companyUser.email,
        }}
        onClose={() => setIsOpenRecoverPasswordModal(false)}
      />
    </Modal>
  );
};

export default EditCompanyUserModal;