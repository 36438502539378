import loading from "assets/gif/loading.gif";
import { useApp } from "contexts";
import React, { useEffect, useState } from "react";
import { Container } from "./styles";

interface LoadingProps {
  shown: boolean;
}

const Loading: React.FC<LoadingProps> = ({ shown }) => {
  const { setLoading } = useApp();
  const [showCancellation, setShowCancellation] = useState(false);

  useEffect(() => {
    setShowCancellation(false);
    const timer = setTimeout(() => {
      setShowCancellation(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, [shown]);

  return (
    <Container shown={shown}>
      <img src={loading} alt="Legal Control Atas Loading" />
      <p
        className={`${showCancellation ? "shown" : ""}`}
        aria-hidden
        onClick={() => setLoading(false)}
      >
        Isso pode levar alguns instantes, clique aqui para continuar usando o
        sistema
      </p>
    </Container>
  );
};

export default Loading;
