import styled from "styled-components";

export const Container = styled.form`
  min-width: 20rem;
  width: 80%;
  max-width: 80rem;
  background-color: ${({theme})=> theme.colors.background};
  border-radius: 4px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  padding: 1rem;
`

export const Content = styled.div`
  border: 1px solid ${({theme})=> theme.colors.primary};
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    &:hover{
      filter: brightness(0.9);
    }
  }
`

export const ListExternalFonts = styled.div`
  height: 10rem;
  overflow: auto;
  padding: 4px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`
export const ListRecklessTrader = styled.div`
  border: 1px solid ${({theme}) => theme.colors.primary};
  padding: 1rem;
  border-radius: 4px;
  & .content{
    max-height: 30rem;
    padding-top: 0.5rem;
    overflow: auto;
    width: 100%;
    margin-bottom: 1rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & .endline{
    align-self: flex-end;
  }
`