import styled, {keyframes} from "styled-components"

const colorChange = keyframes`
  0% {
    background-color: #09ff0009; /* Verde claro */
    color: #09ff00ff; /* Cor do texto */
  }
`;

export const NodeContainer = styled.div`
  *{
    transition: 500ms;
  }
`
export const NodeContent = styled.button<{maxLenght: number}>`
  border-radius: 2px;
  background-color: ${({ theme: {colors: {background_white}} }) => background_white};
  color: ${({ theme: {colors: {text}} }) => text};
  padding: 0.3rem 1rem;
  border: none;
  box-shadow: 0 0 0.4rem #00000025;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(${({maxLenght}) => maxLenght}rem / 1.5);
  h1{
    color: ${({theme: {colors: {primary}}}) => primary};
    white-space: nowrap;
  }
  span{
    white-space: nowrap;
  }
  textarea{
    border: none;
    border-top: 1px solid;
    width: 100%;
    font-size: 0.7rem;
    color: ${({theme: {colors: {primary}}}) => primary};
    padding: 0.4rem 0.2rem;
  }
`

export const NodeOptions = styled.div`
  position: absolute;
  margin-left: 1rem;
  margin-top: -0.2rem;
  width: max-content;
  border-radius: 2px;
  background-color: ${({ theme: {colors: {background_white}} }) => background_white};
  color: ${({ theme: {colors: {text}} }) => text};
  box-shadow: 0 0 0.4rem #00000025;
  border: none;
  z-index: 10000;

  button{
    border: none;
    background: none;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    min-width: 100%;
    font-size: 0.7rem;
    padding: 0.3rem 1rem;
    color: ${({ theme: {colors: {text}} }) => text};
    &:hover{
      background-color: #aacff11e;
      transition: 300ms ease-in-out;
    }

    &.copying {
      animation: ${colorChange} 0.5s linear infinite;
    }
  }

  .delete{
    color: ${({ theme: {colors: {red}} }) => red};

    &:hover{
      background-color: #b100000c;
      transition: 300ms ease-in-out;
    }
  }
`
