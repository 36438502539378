import { createTheme, ThemeProvider as MUITheProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import ErrorBoundary from "components/ErrorBoundary";
import GlobalContext from "contexts";
import { GlobalStyle } from "global/styles";
import light from "global/styles/themes/light";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import Router from "routes";
import { ThemeProvider } from "styled-components";
import reportWebVitals from "utils/reportWebVitals";
import { ReactFlowProvider } from 'reactflow';

const theme = createTheme({
palette: {
  primary: {
      main: light.colors.primary,
    },
    background: {
      default: light.colors.background,
    },
  },
});
moment.locale("pt-br");

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ReactFlowProvider>
    <ErrorBoundary>
      <React.StrictMode>
          <MUITheProvider theme={theme}>
            <ThemeProvider theme={light}>
              <QueryClientProvider client={queryClient}>
                <GlobalContext>
                  <CssBaseline />
                  <GlobalStyle />
                  <Router />
                </GlobalContext>
              </QueryClientProvider>
            </ThemeProvider>
          </MUITheProvider>
      </React.StrictMode>
    </ErrorBoundary>
  </ReactFlowProvider>
);
reportWebVitals();
