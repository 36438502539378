import { DossierContextProps } from "contexts-types";
import useWebSocket from "hooks/useWebSocket";
import { Columns, FilterProps, Props } from "props";
import React, { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import { DossierPdfStatus, DossierStatus, ProcessStatus } from "types/enums";
import { IDossierViewModel } from "viewModels";
import { useAuth } from "./auth";
import { useInfiniteQuery } from "react-query";
import { IOrderFieldInputModel } from "inputModels";
import { DossierController } from "controllers";
import { useAlert } from "./alert";


const PAGE_SIZE = 15;
const DossierContext = createContext<DossierContextProps>({} as DossierContextProps);

const Dossier: React.FC<Props> = ({ children }) => {
  const { user, logged } = useAuth();
  const [dossiers, setDossiers] = useState<IDossierViewModel[]>([]);
  const [orderBy, setOrder] = useState<IOrderFieldInputModel<Columns>[]>([]);
  const confirm = useAlert();
  const [selectedDownloadType, setSelectedDownloadType] = useState<"pdf" | "zip">("pdf");
  const [filter, setFilter] = useState<FilterProps>();
  const {
    data: results,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["dossiers", orderBy, filter],
    ({ pageParam = 1 }) =>
      DossierController.getByUserId<Columns>({
        orderBy,
        page: pageParam,
        take: PAGE_SIZE,
        term: filter?.term,
        filter: filter?.term ? filter?.filter : undefined,
      }).then((res) => res.data),
    {
      enabled: !!user?.id && logged,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    }
  );

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  const handleFetch = () => {
    refetch();
  };

  useEffect(() => {
    setDossiers(results?.pages?.flat() ?? []);
  }, [results, setDossiers]);

  useWebSocket<{ id: string; status: DossierStatus; dateToGenerateAgain: Date; hasFlag:boolean }>({
    url: `dossiers/${user?.id}/update`,
    onMessage: ({ id, status, dateToGenerateAgain, hasFlag}) => {
      setDossiers((prev) => {
        const newDossiers = [...prev];
        const idx = newDossiers.findIndex((d) => d.id === id);
        if (idx !== -1) {
          newDossiers[idx].status = status;
          if (dateToGenerateAgain) newDossiers[idx].dateToGenerateAgain = new Date(dateToGenerateAgain);
          if (hasFlag) newDossiers[idx].hasFlag = hasFlag;
        }
        return newDossiers;
      });
    },
  });
  
  useWebSocket<{ id: string; status: DossierPdfStatus; }>({
    url: `dossiers/${user?.id}/request-pdf`,
    onMessage: ({ id, status }) => {
      setDossiers((prev) => {
        const newDossiers = [...prev];
        const idx = newDossiers.findIndex((d) => d.id === id);
        if (idx !== -1) newDossiers[idx].pdfStatus = status;

        return newDossiers;
      });
    },
  });

  useWebSocket<{ id: string; status: ProcessStatus; }>({
    url: `dossiers/${user?.id}/update-process-status`,
    onMessage: ({ id, status }) => {
      setDossiers((prev) => {
        const newDossiers = [...prev];
        const idx = newDossiers.findIndex((d) => d.id === id);
        if (idx !== -1) newDossiers[idx].processStatus = status;
        return newDossiers;
      });
    },
  });
  
  const handleDownload = (id: string, option: "pdf" | "zip") => {
    switch (option) {
      case "pdf":
        DossierController.download(id, option)
          .then((url: string) => {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${id}.${option}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch(() => {
            confirm.show({
              type: "error",
              title: "Falha ao baixar",
              timeout: 5000,
              description: `Algum erro aconteceu ao baixar o arquivo ${option.toUpperCase()}.\nTente novamente...`,
              retry: {
                show: false,
              },
              cancel: {
                label: "Ok",
              },
            });
          })
        break;
      case "zip":
        DossierController.download(id, "zip")
          .then((url: string) => {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${id}.${option}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch(async () => {
            await confirm.show({
              type: "error",
              title: "Falha ao baixar",
              timeout: 5000,
              description:
                "Algum erro aconteceu ao baixar o arquivo ZIP.\nTente novamente...",
              retry: {
                show: false,
              },
              cancel: {
                label: "Ok",
              },
            });
          })
        break;
      default:
        break;
    }
  };

  return (
    <DossierContext.Provider
      value={{
        dossiers,
        setDossiers,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        orderBy,
        setOrder,
        filter,
        setFilter,
        handleFetchNextPage,
        handleFetch,
        handleDownload,
        selectedDownloadType,
        setSelectedDownloadType,
      }}
    >
      {children}
    </DossierContext.Provider>
  );
};

export const useDossier: () => DossierContextProps = () => useContext(DossierContext);

export default Dossier;
