import { CollaboratorUserContextProps } from "contexts-types";
import { CollaboratorUserController } from "controllers/CollaboratorUserController";
import { Columns, FilterProps, Props } from "props";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { ICollaboratorUserInfosViewModel, ICollaboratorUserViewModel } from "viewModels";
import { useAuth } from "./auth";
import { IOrderFieldInputModel } from "inputModels";
import { TypeUser } from "types/enums";

const PAGE_SIZE = 15;
const CollaboratorUserContext = createContext<CollaboratorUserContextProps>({} as CollaboratorUserContextProps);

const CollaboratorUser: React.FC<Props> = ({ children }) => {
  const { user, logged } = useAuth();
  const [orderBy, setOrder] = useState<IOrderFieldInputModel<Columns>[]>([]);
  const [collaboratorUsers, setCollaboratorUsers] = useState<ICollaboratorUserViewModel[]>([]);
  const [collaboratorUserInfo, setCollaboratorUserInfo] = useState<ICollaboratorUserInfosViewModel>();
  const [filter, setFilter] = useState<FilterProps>();

  const {
    data: results,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["collaboratorUsers", orderBy, filter],
    ({ pageParam = 1 }) =>
      CollaboratorUserController.getByCollaboratorId(
        {
          orderBy,
          page: pageParam,
          take: 15,
          term: filter?.term,
          filter: filter?.term ? filter?.filter : undefined,
        }
      ).then((res) => res.data),
    {
      enabled: !!user?.id && logged,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    },
  );
  useQuery(
    "collaboratorUserInfos",
    () => CollaboratorUserController.getCollaboratorInfoById(),
    {
      onSuccess: (response) => {
        setCollaboratorUserInfo(response.data);
      },
      refetchOnWindowFocus: false,
      enabled: logged && !!user?.id && user.typeUser === TypeUser.COLLABORATOR,
    }
  );

  const handleFetch = () => {
    refetch();
  };

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  useEffect(() => {
    setCollaboratorUsers(results?.pages?.flat() ?? []);
  }, [results, setCollaboratorUsers]);

  return (
    <CollaboratorUserContext.Provider
      value={{
        collaboratorUsers,
        setCollaboratorUsers,
        orderBy,
        setOrder,
        filter,
        setFilter,
        collaboratorUserInfo,
        handleFetch,
        handleFetchNextPage,
        hasNextPage,
        isFetchingNextPage,
      }}
    >
      {children}
    </CollaboratorUserContext.Provider>
  );
};

export const useCollaboratorUser: () => CollaboratorUserContextProps = () => useContext(CollaboratorUserContext);

export default CollaboratorUser;

