import styled from "styled-components";

export const Container = styled.div`
    height: 320px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
    margin-left: 1rem;
    p, li{
        margin-left: 1.2rem;
    }
    ul{
        margin-left: 2.3rem;
    }
    p{
        text-indent: 2.3rem;
    }
    span{
        text-decoration: underline;
    }
`;
