import styled from "styled-components";

export const ShowMenuItem = styled.div`
  display: none;

  @media (max-width: 840px) {
    display: block;
  }
`;

export const HideMenuItem = styled.div`
  display: block;

  @media (max-width: 840px) {
    display: none;
  }
`;

export const LogoCenterResponse = styled.div`
  width: 100%;
  @media (max-width: 840px) {
    width: 37px;
  }
`;
