import { ContentPasteSearch, MoreVert, PowerSettingsNew, AssignmentInd, Assignment, MenuBook, Warning, Domain , Map} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import {
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  AppBar as MuiAppBar,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Logo from "assets/png/logo.png";
import { useApp, useAuth, useCollaboratorUser } from "contexts";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Notification from "../Notification";
import { useState } from "react";
import { HideMenuItem, LogoCenterResponse, ShowMenuItem } from "./styles";
import { ManagementStatus, TypeUser } from "types/enums";

function AppBar() {
  const { user } = useAuth();
  const { isExitConfirm, setIsExitConfirm } = useApp();
  const theme = useTheme();
  const { collaboratorUserInfo } = useCollaboratorUser();
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const options = [
    {
      id: "logs",
      label: "Registros de dossiês",
      icon: <Assignment />,
      action: () => handleNavigate("/logs"),
    },
    {
      id: "logsUser",
      label: "Registros de usuários",
      icon: <AssignmentInd />,
      action: () => handleNavigate("/logsUser"),
    },
    {
      id: "financialReport",
      label: "Relatório Financeiro",
      icon: <MenuBook />,
      action: () => handleNavigate("/financialReport"),
    },
  ];


  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigate = (path: string) => {
    if(!isExitConfirm || window.confirm('Há informações não salvas, deseja mesmo sair?')){
      navigate(path)
      setIsExitConfirm(false)
    }
  }

  return (
    <MuiAppBar position="fixed" color="default">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <ShowMenuItem>
            <IconButton onClick={toggleDrawer}>
              <MenuIcon color="primary" />
            </IconButton>
          </ShowMenuItem>
          <Drawer anchor="left" open={open} onClose={toggleDrawer}>
            <Grid width="20rem">
              <List>
                <Grid>
                  <Button fullWidth style={{ textTransform: "none", justifyContent: "left", alignItems: "center", padding: "0.5rem 1rem" }} onClick={() => handleNavigate("/search")}>
                    <ListItemIcon >
                      <ContentPasteSearch />
                    </ListItemIcon>
                    <Typography
                      component="p"
                      variant="h5"
                      color="primary"
                      sx={{
                        cursor: "pointer"
                      }}>
                      Dossiês
                    </Typography>
                  </Button>
                  <Button fullWidth style={{ textTransform: "none", justifyContent: "left", alignItems: "center", padding: "0.5rem 1rem" }} onClick={() => handleNavigate("/relationsMap")}>
                    <ListItemIcon >
                      <Map />
                    </ListItemIcon>
                    <Typography
                      component="p"
                      variant="h5"
                      color="primary"
                      sx={{
                        cursor: "pointer"
                      }}>
                      Mapa Relacional
                    </Typography>
                  </Button>
                  {(user?.typeUser !== TypeUser.COLLABORATOR || collaboratorUserInfo?.managementStatus.includes(ManagementStatus.MODEL)) && (
                    <Grid>
                      <Button fullWidth style={{ textTransform: "none", justifyContent: "left", alignItems: "center", padding: "0.5rem 1rem" }} onClick={() => handleNavigate("/models")}>
                        <ListItemIcon >
                          <Assignment />
                        </ListItemIcon>
                        <Typography
                          component="p"
                          variant="h5"
                          color="primary"
                          sx={{
                            cursor: "pointer"
                          }}>
                          Modelos
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                  {(user?.typeUser !== TypeUser.COLLABORATOR || collaboratorUserInfo?.managementStatus.includes(ManagementStatus.USER)) && (
                    <Grid>
                      <Button fullWidth style={{ textTransform: "none", justifyContent: "left", alignItems: "center", padding: "0.5rem 1rem" }} onClick={() => handleNavigate("/users")}>
                        <ListItemIcon >
                          <AssignmentInd />
                        </ListItemIcon>
                        <Typography
                          component="p"
                          variant="h5"
                          color="primary"
                          sx={{
                            cursor: "pointer"
                          }}>
                          Usuários
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                  {(user?.typeUser !== TypeUser.COLLABORATOR || collaboratorUserInfo?.managementStatus.includes(ManagementStatus.PODEN)) && (
                    <Grid>
                      <Button fullWidth style={{ textTransform: "none", justifyContent: "left", alignItems: "center", padding: "0.5rem 1rem" }} onClick={() => handleNavigate("/recklessTrader")}>
                        <ListItemIcon >
                          <AssignmentInd />
                        </ListItemIcon>
                        <Typography
                          component="p"
                          variant="h5"
                          color="primary"
                          sx={{
                            cursor: "pointer"
                          }}>
                          Ponderadores
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                  {user?.typeUser === TypeUser.ADM && (
                    <Grid>
                      <Button fullWidth style={{ textTransform: "none", justifyContent: "left", alignItems: "center", padding: "0.5rem 1rem" }} onClick={() => handleNavigate("/companies")}>
                        <ListItemIcon >
                          <Domain />
                        </ListItemIcon>
                        <Typography
                          component="p"
                          variant="h5"
                          color="primary"
                          sx={{
                            cursor: "pointer"
                          }}>
                          Empresas
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                  <Button fullWidth style={{ textTransform: "none", justifyContent: "left", alignItems: "center", padding: "0.5rem 1rem" }} onClick={() => signOut()}>
                    <ListItemIcon >
                      <PowerSettingsNew />
                    </ListItemIcon>
                    <Typography
                      component="p"
                      variant="h5"
                      color="primary"
                      sx={{
                        cursor: "pointer"
                      }}>
                      Sair
                    </Typography>
                  </Button>
                </Grid>
              </List>
            </Grid>
          </Drawer>
          <Grid
            display="flex"
            onClick={() => handleNavigate("/search")}
            sx={{
              cursor: "pointer"
            }}>
            <img src={Logo} alt="logo legal audit" width="200rem" />
          </Grid>
          <HideMenuItem>
            <Grid gap="2rem" display="flex" marginLeft="4rem">
              <Tooltip
                title={
                  <Typography
                    component="p"
                    variant="subtitle2">
                    Gerenciar dossiês
                  </Typography>
                }
                arrow
              >
                <Typography
                  component="p"
                  variant="h5"
                  color="primary"
                  onClick={() => handleNavigate("/search")}
                  sx={{
                    cursor: "pointer"
                  }}>
                  Dossiês
                </Typography>
              </Tooltip>
              <Tooltip
                title={
                  <Typography
                    component="p"
                    variant="subtitle2">
                    Mapa de relações
                  </Typography>
                }
                arrow
              >
                <Typography
                  component="p"
                  variant="h5"
                  color="primary"
                  onClick={() => handleNavigate("/relationsMap")}
                  sx={{
                    cursor: "pointer"
                  }}>
                  Mapa
                </Typography>
              </Tooltip>
              {(user?.typeUser !== TypeUser.COLLABORATOR || collaboratorUserInfo?.managementStatus.includes(ManagementStatus.MODEL)) && (
                <Tooltip
                  title={
                    <Typography
                      component="p"
                      variant="subtitle2">
                      Gerenciar Modelos
                    </Typography>
                  }
                  arrow
                >
                  <Typography
                    component="p"
                    variant="h5"
                    color="primary"
                    onClick={() => handleNavigate("/models")}
                    sx={{
                      cursor: "pointer"
                    }}>
                    Modelos
                  </Typography>
                </Tooltip>
              )}
              <Tooltip
                title={
                  <Typography
                    component="p"
                    variant="subtitle2">
                    Ponderadores de risco
                  </Typography>
                }
                arrow
              >
                <Typography
                  component="p"
                  variant="h5"
                  color="primary"
                  onClick={() => handleNavigate("/recklessTrader")}
                  sx={{
                    cursor: "pointer"
                  }}>
                  Ponderadores
                </Typography>
              </Tooltip>
              {(user?.typeUser !== TypeUser.COLLABORATOR || collaboratorUserInfo?.managementStatus.includes(ManagementStatus.USER)) && (
                <Tooltip
                  title={
                    <Typography
                      component="p"
                      variant="subtitle2">
                      Gerenciar usuários
                    </Typography>
                  }
                  arrow
                >
                  <Typography
                    component="p"
                    variant="h5"
                    color="primary"
                    onClick={() => handleNavigate("/users")}
                    sx={{
                      cursor: "pointer"
                    }}>
                    Usuários
                  </Typography>
                </Tooltip>
              )}
              {user?.typeUser === TypeUser.ADM && (
                <Typography
                  component="p"
                  variant="h5"
                  color="primary"
                  onClick={() => handleNavigate("/companies")}
                  sx={{
                    cursor: "pointer"
                  }}>
                  Empresas
                </Typography>
              )}
            </Grid>
          </HideMenuItem>
          <LogoCenterResponse>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Notification />
              </Grid>
              <HideMenuItem>
                <Grid item>
                  <Tooltip title="Abrir opções">
                    <IconButton onClick={handleOpenUserMenu}>
                      <MoreVert color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menuAppbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {user?.typeUser === TypeUser.ADM &&
                        options.map((option, idx) =>
                          option === null ? (
                            <Divider key={`divider${idx}`} />
                          ) : (
                            <MenuItem
                              key={option.id}
                              style={{ minWidth: "10rem" }}
                              onClick={() => {
                                handleCloseUserMenu();
                                option.action();
                              }}
                              sx={{
                                transition: "all 0.5s ease",
                                ":hover svg": {
                                  transition: "all 0.5s ease",
                                },
                              }}
                            >
                              <ListItemIcon style={{color:theme.palette.primary.light}}>{option.icon}</ListItemIcon>
                              <Typography textAlign="center">{option.label}</Typography>
                            </MenuItem>
                          )
                        )}
                    <MenuItem
                      style={{ textTransform: "none", justifyContent: "left", alignItems: "center", padding: "0.5rem 1rem" }} onClick={() => handleNavigate("/relationsMap")}
                    >
                      <ListItemIcon style={{ color: theme.palette.primary.light }}><Map /></ListItemIcon>
                      <Typography
                        style={{ minWidth: "7rem" }}>
                        Mapa de relações
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      style={{ textTransform: "none", justifyContent: "left", alignItems: "center", padding: "0.5rem 1rem" }} onClick={() => signOut()}
                    >
                      <ListItemIcon style={{ color: theme.palette.error.main }}><PowerSettingsNew /></ListItemIcon>
                      <Typography
                        style={{ minWidth: "7rem" }}>
                        Sair
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </HideMenuItem>
            </Grid>
          </LogoCenterResponse>
        </Toolbar>
      </Container >
    </MuiAppBar >
  );
}
export default AppBar;
