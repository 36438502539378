/* eslint-disable react/prop-types */
import { NodeElement } from "components/node"
import { Props } from "props"
import { createContext, useContext, useEffect, useState } from "react"
import { Node, 
  Edge, 
  EdgeChange, 
  NodeChange, 
  Position, 
  useNodesState, 
  useEdgesState, 
  ReactFlowProvider,
  XYPosition
 } from "reactflow"
import { IConsultCnpj } from "types/entities"
import { INodeElementOptions } from "types/props"
import { v4 as uuid} from 'uuid'
import { IPrivateIndividualInformationBaseViewModel } from "viewModels"

interface RelationMapContextProps {
  nodes: Node[],
  edges: Edge[],
  refreshNodes: () => void
  onNodesChange: (changes: NodeChange[]) => void,
  onEdgesChange: (changes: EdgeChange[]) => void,
  createNode: (    
    element: INodeElementOptions,
    createdBy: INodeElementOptions
  ) => void,
  company: IConsultCnpj | null,
  setCompany: (value: IConsultCnpj | null) => void,
  privateIndividual: IPrivateIndividualInformationBaseViewModel | null,
  setPrivateIndividual: (value: IPrivateIndividualInformationBaseViewModel | null) => void,
  deleteNode: (element: INodeElementOptions) => void,
  searchoptions: string[],
  location: (search: string) => void,
  center: XYPosition
  onNodeDrag: (node: Node) => void,
  imageName: string | null,
  setImageName: (value: string | null) => void,
}

interface searchProps{
  id: string,
  label: string
}

const RelationMapContext = createContext({} as RelationMapContextProps)

export const useRelationMap = () => useContext(RelationMapContext)

export const RelationMapProvider = ({children}: Props) => {

  const [center,setCenter] = useState<XYPosition>({
    x: 0,
    y: 0,
  })

  const [company,setCompany] = useState<IConsultCnpj | null>(null)
  const [privateIndividual,setPrivateIndividual] = useState<IPrivateIndividualInformationBaseViewModel | null>(null)
  const [nodes,setNodes, onNodesChange] = useNodesState([])
  const [edges,setEdges, onEdgesChange] = useEdgesState([])
  const [value, setValue] = useState<INodeElementOptions>()
  const [searchoptions, setSearchoptions] = useState<string[]>([])
  const [imageName, setImageName] = useState<string | null>(null)
  const [saveType, setSaveType] = useState<'image' | 'pdf'>('image')

  useEffect(()=>{
    if(value){
      const allValuesOn = getAllLabel(value)
      setSearchoptions(Array.from(new Set(allValuesOn.map(v => v.label))))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[nodes])


  useEffect(()=>{
    refreshNodes()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[company,privateIndividual])

  const location = (search: string) => {
    if(value){
      const id = getAllLabel(value).filter(v => v.label === search).map(v => v.id)[0]
      if(id !== undefined){
        const position = getPosition(id)
        setCenter(position)
      }
    }
    return 
  }

  const returnValue = (input?: string | number | null) => {
    if(input === undefined || input === null || input === '')
      return undefined
    return typeof input === 'string'? input : input.toString()
  }

  const refreshNodes = () => {
    if(company){
      const nValue: INodeElementOptions = {
        id: '0',
        isOpen: true,
        label: 'Nome da Empresa',
        value: company?.companyName ?? "Não encontrado",
        children: [
          {
            id: '1',
            label: "Nome Fantasia",
            value: returnValue(company.fantasyName),
            isOpen: false,
          },
          {
            id: '2',
            label: 'Documento',
            value: returnValue(company.document),
            isOpen: false,
          },
          {
            id: '3',
            label: 'Data de abertura',
            value: returnValue(company.openDate),
            isOpen: false,
          },
          {
            id: '4',
            label: 'Localização',
            value: 'Endereço',
            isOpen: false,
            children: [
              {
                id: '41',
                label: 'Bairro',
                value: returnValue(company.address.neighborhood),
                isOpen: false,
              },
              {
                id: '42',
                label: 'Número',
                value: returnValue(company.address.number),
                isOpen: false,
              },
              {
                id: '43',
                label: 'Cidade',
                value: returnValue(company.address.city),
                isOpen: false,
              },
              {
                id: '44',
                label: 'Rua',
                value: returnValue(company.address.street),
                isOpen: false,
              },
              {
                id: '45',
                label: 'Complemento',
                value: returnValue(company.address.complement),
                isOpen: false,
              },
              {
                id: '46',
                label: 'Estado',
                value: returnValue(company.address.state),
                isOpen: false,
              },
              {
                id: '47',
                label: 'Cep',
                value: returnValue(company.address.zipCode),
                isOpen: false,
              },
              {
                id: '48',
                label: 'País',
                value: returnValue(company.address.country),
                isOpen: false,
              }
            ]
          },
          {
            id: '5',
            label: 'Sócios',
            value: 'Quadro de Sócios e Administradores',
            isOpen: false,
            children: company.qsa?.map(qsa => 
              {
                const id = uuid()
                return {
                  id: id,
                  label: qsa.partnerName ?? 'nome não encontrado',
                  value: '',
                  isOpen: false,
                  children: [
                    {
                      id: `${id}1`,
                      label: 'país',
                      value: returnValue(qsa.country),
                      isOpen: false,
                    },
                    {
                      id: `${id}2`,
                      label: 'Código do país',
                      value: returnValue(qsa.countryCode),
                      isOpen: false,
                    },
                    {
                      id: `${id}3`,
                      label: 'Faixa etária',
                      value: returnValue(qsa.ageRange),
                      isOpen: false,
                    },
                    {
                      id: `${id}4`,
                      label: 'Código da Faixa etária',
                      value: returnValue(qsa.ageRangeCode),
                      isOpen: false,
                    },
                    {
                      id: `${id}5`,
                      label: 'Data de Entrada na sociedade',
                      value: returnValue(qsa.entryDate),
                      isOpen: false,
                    },
                    {
                      id: `${id}6`,
                      label: 'Cnpj/Cpj do sócio',
                      value: returnValue(qsa.documentPartner),
                      isOpen: false,
                    },
                    {
                      id: `${id}7`,
                      label: 'Qualificação do sócio',
                      value: returnValue(qsa.qualificationPartner),
                      isOpen: false,
                    },
                    {
                      id: `${id}8`,
                      label: 'Código de Qualificação do sócio',
                      value: returnValue(qsa.qualificationPartnerCode),
                      isOpen: false,
                    },
                    {
                      id: `${id}9`,
                      label: 'Identificador de Sócio',
                      value: returnValue(qsa.partnerIdentifier),
                      isOpen: false,
                    },
                    {
                      id: `${id}10`,
                      label: 'Representante Legal',
                      value: returnValue(qsa.legalRepName),
                      isOpen: false,
                      children: [
                        {
                          id: `${id}11`,
                          label: 'Cpf',
                          value: returnValue(qsa.legalRepCpf),
                          isOpen: false,
                        },
                        {
                          id: `${id}12`,
                          label: 'Qualificacao',
                          value: returnValue(qsa.legalRepQualification),
                          isOpen: false,
                        },
                        {
                          id: `${id}13`,
                          label: 'Código de Qualificacao',
                          value: returnValue(qsa.legalRepQualificationCode),
                          isOpen: false,
                        }
                      ]
                    }
                  ]
                }
              }
            )
          }
        ] 
      }
      setValue(nValue)
      if(nValue){
        setNodes([
          {
            id: '0',
            data: {
              label: <NodeElement key={'0'} element={nValue} root />
            },
            sourcePosition: Position.Right,
            position: {
              x: 0,
              y: 0
            }
          }
        ])
      }
      setEdges([])
      location('Nome da Empresa')
    }
    if(privateIndividual){
      const nValue: INodeElementOptions = {
        id: '0',
        isOpen: true,
        label: 'Nome',
        value: privateIndividual.name ?? "Não encontrado",
        children: [
          {
            id: '1',
            isOpen: false,
            label: 'Documento',
            value: privateIndividual.document
          },
          {
            id: '2',
            isOpen: false,
            label: 'Nome da mãe',
            value: privateIndividual.motherName
          },
          {
            id: '3',
            isOpen: false,
            label: 'Data de nascimento',
            value: privateIndividual.birthDate ? new Date(privateIndividual.birthDate).toDateString() : undefined
          },
          {
            id: '4',
            isOpen: false,
            label: 'Educação',
            value: privateIndividual.education
          },
          {
            id: '5',
            isOpen: false,
            label: 'Gênero',
            value: privateIndividual.gender
          },
          {
            id: '6',
            isOpen: false,
            label: 'Data da consulta RFB',
            value: new Date(privateIndividual.dateConsultRFB).toDateString()
          },
          {
            id: '7',
            isOpen: false,
            label: 'Endereço',
            value: privateIndividual.address === undefined ? undefined : `Última vez consultado: ${new Date(privateIndividual.address.updatedDate).toDateString()}`,
            children: [
              {
                id: '71',
                isOpen: false,
                label: 'Rua',
                value: privateIndividual.address === undefined ? undefined : privateIndividual.address.street
              },
              {
                id: '72',
                isOpen: false,
                label: 'Número',
                value: privateIndividual.address === undefined ? undefined : privateIndividual.address.number
              },
              {
                id: '73',
                isOpen: false,
                label: 'Bairro',
                value: privateIndividual.address === undefined ? undefined : privateIndividual.address.neighborhood
              },
              {
                id: '74',
                isOpen: false,
                label: 'Cidade',
                value: privateIndividual.address === undefined ? undefined : privateIndividual.address.city
              },
              {
                id: '75',
                isOpen: false,
                label: 'Unidade Federal',
                value: privateIndividual.address === undefined ? undefined : privateIndividual.address.uf
              },
              {
                id: '76',
                isOpen: false,
                label: 'Código zip',
                value: privateIndividual.address === undefined ? undefined : privateIndividual.address.zipCode
              },
              {
                id: '77',
                isOpen: false,
                label: 'Código Ibge',
                value: privateIndividual.address === undefined ? undefined : privateIndividual.address.codeIBGE.toString()
              },
              {
                id: '78',
                isOpen: false,
                label: 'Latitude',
                value: privateIndividual.address === undefined ? undefined : privateIndividual.address.latitude ? privateIndividual.address.latitude.toString() : undefined
              },
              {
                id: '79',
                isOpen: false,
                label: 'Longitude',
                value: privateIndividual.address === undefined ? undefined : privateIndividual.address.longitude ? privateIndividual.address.longitude.toString() : undefined
              }
            ]
          },
          {
            id: '8',
            isOpen: false,
            label: 'Trabalho',
            value: privateIndividual.office
          },
          {
            id: '9',
            isOpen: false,
            label: 'Renda presumida',
            value: privateIndividual.presumptiveIncome
          },
          {
            id: '10',
            isOpen: false,
            label: 'Atividade profissional',
            value: privateIndividual.professionalActivity
          },
          {
            id: '11',
            isOpen: false,
            label: 'Status',
            value: privateIndividual.status ? 'ativo' : 'inativo'
          }
        ]
      }
      setValue(nValue)
      if(nValue){
        setNodes([
          {
            id: '0',
            data: {
              label: <NodeElement key={'0'} element={nValue} root />
            },
            sourcePosition: Position.Right,
            position: {
              x: 0,
              y: 0
            }
          }
        ])
      }
      setEdges([])
      location('Nome da Empresa')
    }
  }

  const getPosition = (id: string) => {
    const node = nodes.filter(n => n.id === id)[0]
    return {
      x:  node.position.x + (node.width ?? 0) / 2,
      y:  node.position.y + (node.height ?? 0) / 2
    } as XYPosition
  }

  const createNode = (
    element: INodeElementOptions,
    createdBy: INodeElementOptions,
  ) => {
    const position = getPosition(createdBy.id)
    setNodes([
      {
        id: element.id,
        data: {
          label: <NodeElement key={element.id} element={element} root={false} />
        },
        position: {
          x: position.x + 200,
          y: position.y - 40
        }
      },...nodes
    ])
    setEdges([
      {
        id: `${createdBy.id}-${element.id}`,
        source: createdBy.id,
        target: element.id,
        sourceHandle: Position.Right,
        targetHandle: Position.Left
      },
      ...edges
    ])
    element.isOpen = true
  }

  const onNodeDrag = (node: Node) => {
    edges.filter(e => e.source === node.id || e.target === node.id).forEach(repositionEdges)
  }

  const repositionEdges = (edge: Edge) => {
    const targetPosition = getPosition(edge.target) 
    const sourcePosition = getPosition(edge.source)
    const target = nodes.filter(n => n.id === edge.target)[0]
    const source = nodes.filter(n => n.id === edge.source)[0]
    const targetHandle = targetPosition.y < sourcePosition.y + (target.height ?? 50) ? targetPosition.y > sourcePosition.y - (target.height ?? 50)? targetPosition.x < sourcePosition.x? Position.Right : Position.Left :  Position.Bottom : Position.Top
    const sourceHandle = targetPosition.y < sourcePosition.y + (source.height ?? 50) ? targetPosition.y > sourcePosition.y - (source.height ?? 50)? targetPosition.x < sourcePosition.x? Position.Left : Position.Right :  Position.Top : Position.Bottom
    setEdges(edges.map(e => {
      if(e.id === edge.id){
        e.targetHandle = targetHandle
        e.sourceHandle = sourceHandle
      }
      return e
    }))
  }

  const deleteNode = (element: INodeElementOptions) => {
    const deniedIds = getAllIds(element)
    setNodes(nodes.filter(n => !deniedIds.includes(n.id)))
    setEdges(edges.filter(e => !deniedIds.includes(e.target) && !deniedIds.includes(e.source)))
    element.isOpen = false
  }

  const getAllLabel = (node: INodeElementOptions) => {
    const values: searchProps[] = [{
      id: node.id,
      label: node.label,
    }];
    if (node.children) {
      node.children.filter(c => c.isOpen === true).forEach((child) => {
        values.push(...getAllLabel(child));
      });
    }
    return values;
  }

  function getAllIds(node: INodeElementOptions, noFalse?: boolean): string[] {
    const ids: string[] = [node.id];
    if(noFalse !== true)
      node.isOpen = false;
    if (node.children) {
      node.children.filter(c => c.isOpen === true).forEach((child) => {
        ids.push(...getAllIds(child, noFalse));
      });
    }
    return ids;
  }

  return(
    <ReactFlowProvider>
      <RelationMapContext.Provider value={{
        nodes,
        edges,
        onNodesChange,
        refreshNodes,
        onEdgesChange,
        createNode,
        company,
        setCompany,
        deleteNode,
        searchoptions,
        location,
        onNodeDrag,
        center,
        imageName, 
        setImageName,
        privateIndividual,
        setPrivateIndividual
      }}>
        {children}
      </RelationMapContext.Provider>
    </ReactFlowProvider>

  ) 
}