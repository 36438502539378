/* eslint-disable react/jsx-no-duplicate-props */
import { useState } from "react";
import { RemoveRedEye, VisibilityOff, Search } from "@mui/icons-material";
import { toPattern } from "vanilla-masker";
import { useController } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";
import { TextField, TextFieldProps, IconButton } from "@mui/material";
import { Container } from "./styles";

type Props = TextFieldProps & {
  errorText?: string;
  mask?: string;
  name: string;
  width?: string;
  control: any;
  autocomplete?: string;
  lengthInput?: number;
  preventNotNumbers?: boolean;
  endAdornment?: JSX.Element;
  onCallBack?: () => void;
};

export function Input({
  type,
  errorText,
  mask,
  name,
  control,
  color,
  lengthInput,
  width,
  preventNotNumbers,
  endAdornment,
  onCallBack,
  autocomplete,
  ...props
}: Props) {
  const {
    field: { value, ref, onChange },
  } = useController({
    name,
    control,
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(e);
    props.onChange?.(e);
  };
  return (
    <Container>
      <TextField
        type={type === "password" && showPassword ? "text" : type}
        {...props}
        ref={ref}
        inputRef={ref}
        autoComplete={autocomplete || undefined}
        onChange={handleChange}
        value={mask ? toPattern(value ?? "", mask) : value}
        color={color}
        style={{ width }}
        onKeyDown={
          preventNotNumbers
            ? (e) => {
                if (
                  (Number(e.key) < 0 && Number(e.key) > 9) ||
                  isNaN(Number(e.key))
                ) {
                  e.preventDefault();
                }
              }
            : () => {
                ("");
              }
        }
        inputProps={{
          maxLength: lengthInput,
          max: 999,
          min: 1,
        }}
        InputProps={{
          endAdornment: (
            <>
              {type === "password" && (
                <IconButton
                  color={color}
                  type="button"
                  className="iconBtn"
                  onClick={() => setShowPassword((state) => !state)}
                >
                  {showPassword ? (
                    <RemoveRedEye color={color} />
                  ) : (
                    <VisibilityOff color={color} />
                  )}
                </IconButton>
              )}
              {type === "searchInput" && (
                <IconButton
                  color={color}
                  type="button"
                  className="iconBtn"
                  onClick={onCallBack}
                >
                  <Search />
                </IconButton>
              )}
              {endAdornment && endAdornment}
            </>
          ),
        }}
      />

      {errorText && (
        <span className="fieldError">
          <ErrorIcon fontSize="inherit" /> {errorText}
        </span>
      )}
    </Container>
  );
}
