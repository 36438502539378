import {
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
  Search,
  Sort,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { AppBar } from "components";
import moment from "moment";
import { useEffect, useState } from "react";
import { LogStatus, LogStatusText } from "types/enums";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { EXTERNAL_FONT_NAMES } from "../../constants/";
import { Content } from "./styles";
import { Controller, useForm } from "react-hook-form";
import { Columns } from "props";
import { useLogUser } from "contexts";
import { Cnpj, Cpf } from "utils/masks";
import { useLocation } from "react-router-dom";
import React from "react";

const LogsUser = () => {
  const theme = useTheme();
  const location = useLocation();
  const [dossierId, setDossierId] = useState<string | undefined>(
    location.state?.dossierId
  );
  const {
    logsUser,
    orderBy,
    setOrder,
    hasNextPage,
    handleFetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useLogUser();

  const [openLogs, setOpenLogs] = useState<{ [key: string]: boolean }>({});
  const [logUserFiltered, setLogUserFiltered] = useState(logsUser);

  useEffect(() => {
    if (dossierId) {
      setLogUserFiltered(logsUser.filter((l) => l.dossierId === dossierId));
      setDossierId(undefined);
      return;
    }
    setLogUserFiltered(logsUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsUser]);

  const { control, watch } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const data = watch();

  const handleSort = (key: Columns) => () => {
    setOrder((prev) => {
      const newOrder = [...prev];
      const oldOrderIdx = prev.findIndex((o) => o.key === key);
      if (oldOrderIdx === -1) {
        newOrder.push({
          key,
          direction: "asc",
        });
      } else if (newOrder[oldOrderIdx].direction === "asc") {
        newOrder[oldOrderIdx].direction = "desc";
      } else {
        newOrder.splice(oldOrderIdx, 1);
      }
      return newOrder;
    });
  };

  const getSortIcon = (key: Columns) => (
    <IconButton size="small" onClick={handleSort(key)}>
      {orderBy?.find((o) => o.key === key) ? (
        <KeyboardArrowDown
          sx={{
            transition: "all 0.5s ease-in",
            transform:
              orderBy.find((o) => o.key === key)?.direction === "asc"
                ? "rotate(-180deg)"
                : "",
          }}
        />
      ) : (
        <Sort />
      )}
    </IconButton>
  );

  return (
    <>
      <AppBar />
      <Container
        maxWidth="lg"
        sx={{ flexGrow: 1, height: "100vh", overflowY: "auto", pt: 10, pb: 4 }}
      >
        <TableContainer
          component={Paper}
          sx={{
            px: 5,
            pb: 2,
            height: "100%",
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <Grid display="flex" justifyContent="end" margin="0.5rem 0">
            <Controller
              control={control}
              name="filter"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar"
                  variant="standard"
                  type="text"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 250 }}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    sx: {
                      pl: 1,
                    },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
          </Grid>
          <Table stickyHeader aria-label="list-logs">
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    backgroundColor: "white",
                    "& p": {
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    },
                    "& div": {
                      display: "flex",
                      alignItems: "center",

                      "& button": {
                        ml: 1,
                      },
                    },
                  },
                }}
              >
                <TableCell>
                  <Grid>
                    <Typography>#</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Data</Typography>
                    {getSortIcon("date")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>CPF/CNPJ</Typography>
                    {getSortIcon("document")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Usuário</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Descrição</Typography>
                </TableCell>
                <TableCell colSpan={2}>
                  <Typography>Status</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Grid
                      item
                      alignItems="center"
                      sx={{ minHeight: "65px !important" }}
                    >
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando registro de logs...
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : !logsUser.length ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Nenhum log encontrado...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                logUserFiltered
                  .filter((l) =>
                    data.filter
                      ? l.description
                          .toLowerCase()
                          .includes(data.filter.toLocaleLowerCase()) ||
                        new Date(l.createdAt)
                          .toLocaleDateString("pt-BR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                          .includes(data.filter)
                      : logUserFiltered
                  )
                  .map(({ id, description, ...log }, idx) => (
                    <React.Fragment key={id}>
                      <TableRow data-testid='rowLogsUser'
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography color="primary">
                            {(idx + 1).toString().padStart(2, "000")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ whiteSpace: "nowrap" }}>
                            {moment(log.createdAt).format("DD/MM/yyyy HH:mm")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ whiteSpace: "nowrap" }}>
                            {log.dossierDocument
                              ? log.dossierDocument.length === 11
                                ? Cpf(log.dossierDocument)
                                : Cnpj(log.dossierDocument)
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ whiteSpace: "nowrap" }}>
                            {log.emailUser ?? "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{description}</Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setOpenLogs((prevOpenLogs) => ({
                                ...prevOpenLogs,
                                [id]: !prevOpenLogs[id],
                              }));
                            }}
                          >
                            {openLogs[id] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow key={`${id}-collapse`}>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={openLogs[id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ margin: 1.5 }}>
                              <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                color={theme.palette.primary.main}
                              >
                                Status
                              </Typography>
                              <Container>
                                {Object.keys(log.dossier)
                                  .sort((a, b) => a.localeCompare(b))
                                  .map((key) => (
                                    <Content data-testid='contentStatus' key={key}>
                                      <Typography variant="h6" fontWeight={400}>
                                        {EXTERNAL_FONT_NAMES[key]}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        fontWeight={400}
                                        color={
                                          log.dossier[
                                            key as keyof typeof log.dossier
                                          ].status === LogStatus.RESOLVED
                                            ? "green"
                                            : log.dossier[
                                                key as keyof typeof log.dossier
                                              ].status === LogStatus.UNSOLVED
                                            ? "error"
                                            : "info"
                                        }
                                      >
                                        {log.dossier[
                                          key as keyof typeof log.dossier
                                        ].status !== LogStatus.UNSOLVED
                                          ? LogStatusText[
                                              log.dossier[
                                                key as keyof typeof log.dossier
                                              ].status
                                            ]
                                          : `${
                                              LogStatusText[
                                                log.dossier[
                                                  key as keyof typeof log.dossier
                                                ].status
                                              ]
                                            } ${
                                              log.dossier[
                                                key as keyof typeof log.dossier
                                              ].log
                                                ? `(${
                                                    log.dossier[
                                                      key as keyof typeof log.dossier
                                                    ].log
                                                  })`
                                                : "(Registro de log não encontrado)"
                                            }`}
                                      </Typography>
                                    </Content>
                                  ))}
                              </Container>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
              )}
              {hasNextPage && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {isFetchingNextPage ? (
                      <Grid
                        item
                        alignItems="center"
                        sx={{ minHeight: "65px !important" }}
                      >
                        <CircularProgress size={15} />
                        <Typography
                          paddingLeft={1}
                          variant="caption"
                          color="text"
                          sx={{ fontStyle: "italic" }}
                        >
                          Carregando itens...
                        </Typography>
                      </Grid>
                    ) : (
                      <Button
                        onClick={() => handleFetchNextPage()}
                        sx={{ px: 2 }}
                      >
                        <KeyboardDoubleArrowDown />
                        <Typography paddingLeft={2} variant="button">
                          Carregar mais...
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default LogsUser;
