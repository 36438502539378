import { Cancel, LabelImportant, Search } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Modal } from "components";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { ModalProps } from "..";
import {
  IAvailableExternalFontValueViewModel,
  IModelDossierViewModel,
} from "viewModels";
import { useModel } from "contexts";
import { SearchType } from "types/enums";
import { v4 as uuid } from "uuid";
import { Controller, useForm } from "react-hook-form";

interface SelectExternalFontsProps extends Omit<ModalProps, "onSelect"> {
  externalFonts?: {
    key: string;
    value: IAvailableExternalFontValueViewModel;
  }[];
  onSelect?: (selected: string[]) => void;
  externalFontsSelecteds?: string[];
  type: "cpf" | "cnpj" | "all";
}

const SelectExternalFonts: React.FC<SelectExternalFontsProps> = ({
  externalFonts,
  onSelect,
  externalFontsSelecteds,
  type,
  ...props
}: SelectExternalFontsProps) => {
  const { models } = useModel();
  const [modelsFiltered, setModelsFiltered] = useState<
    IModelDossierViewModel[]
  >(models ?? []);
  const [modelsSelected, setModelsSelected] =
    useState<IModelDossierViewModel>();
  const [selectedValue, setSelectedValue] = useState<string>("fonts");
  const allFonts = useMemo(
    () => externalFonts?.map((e) => e.key) ?? [],
    [externalFonts]
  );
  const [fontsSelected, setFontsSelected] = useState<string[]>(allFonts);
  const allSelected = useMemo(
    () => (externalFonts ?? []).length === fontsSelected.length,
    [externalFonts, fontsSelected]
  );
  const [defaultValues] = useState({ filter: "" });
  const { control, watch } = useForm({
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });
  const data = watch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleGenerateDossier = () => {
    selectedValue === "fonts"
      ? onSelect?.(fontsSelected)
      : modelsSelected && onSelect?.(modelsSelected.fonts.map((f) => f.key));
    setFontsSelected(allFonts);
    setModelsSelected(undefined);
    setSelectedValue("fonts");
  };

  const handleSelect = (item: string) => () => {
    if (item === "all") setFontsSelected(allFonts);
    else if (item === "clear") setFontsSelected([]);
    else
      setFontsSelected((prev) => {
        const newSelected = [...prev];
        const idx = newSelected.indexOf(item);
        if (idx !== -1) newSelected.splice(idx, 1);
        else newSelected.push(item);
        return newSelected;
      });
  };

  const handleSelectItem = (item: IModelDossierViewModel) => () => {
    setModelsSelected(() => {
      const newSelected = modelsSelected?.id === item.id ? undefined : item;
      return newSelected;
    });
  };

  useEffect(() => {
    setFontsSelected([]);
  }, [allFonts]);

  useEffect(() => {
    if (externalFontsSelecteds) setFontsSelected(externalFontsSelecteds);
  }, [externalFontsSelecteds]);

  useEffect(() => {
    if (type === "cnpj") {
      setModelsFiltered(models?.filter((m) => m.type !== SearchType.CPF) ?? []);
    } else if (type === "cpf") {
      setModelsFiltered(
        models?.filter((m) => m.type !== SearchType.CNPJ) ?? []
      );
    } else {
      setModelsFiltered(models ?? []);
    }
  }, [models, type]);

  useEffect(() => {
    const filteredModels =
      models?.filter((model) =>
        model.name.toLowerCase().includes(data.filter.toLowerCase())
      ) ?? [];
    setModelsFiltered(filteredModels);
  }, [data.filter, models]);

  return (
    <Modal
      data-testid="external-fonts-mock"
      open={props.open}
      onClose={props.onClose}
    >
      <Grid container justifyContent="space-between">
        <Typography variant="h6" component="h2" color={"primary"}>
          {type !== "all"
            ? "Selecione o tipo para gerar o dossiê:"
            : "Selecione as fontes"}
        </Typography>
        <IconButton size="small" onClick={props.onClose} color="error">
          <Cancel />
        </IconButton>
      </Grid>
      {type !== "all" && (
        <Grid display="flex" justifyContent="center">
          <FormControl component="fieldset">
            <RadioGroup onChange={handleChange} value={selectedValue}>
              <Grid display="flex" flexDirection="row" gap={3}>
                <FormControlLabel
                  value="fonts"
                  control={<Radio />}
                  label="Fontes"
                />
                <FormControlLabel
                  value="models"
                  control={<Radio />}
                  label="Modelos"
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      {selectedValue === "models" && (
        <Grid>
          <Grid display="flex" justifyContent="end">
            <Controller
              control={control}
              name="filter"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar Modelos"
                  variant="standard"
                  type="search"
                  autoComplete="off"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 170 }}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    sx: {
                      pl: 1,
                    },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
          </Grid>
          <Typography variant="h6" component="h2" color={"primary"}>
            Selecione um modelo:
          </Typography>
          {modelsFiltered.length > 0 ? (
            <List dense sx={{ maxHeight: "40vh", overflowY: "auto" }}>
              {modelsFiltered
                ?.filter((item) => !item.disabled)
                .map((item) => (
                  <ListItem
                    data-testid="listModels"
                    key={item.id}
                    onClick={handleSelectItem(item)}
                    sx={{
                      transition: "all 0.5s ease-in-out",
                      cursor: "pointer",
                      borderRadius: 1,
                      userSelect: "none",
                      ":hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <LabelImportant
                        color={
                          modelsSelected?.id === item.id ? "warning" : "inherit"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
            </List>
          ) : (
            <Grid>
              <Typography variant="body1" align="center" color="grey">
                Nenhum modelo encontrado para o tipo de documento do dossiê...
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      {selectedValue === "fonts" && (
        <Grid>
          <Grid display="flex" justifyContent="end">
            <Controller
              control={control}
              name="filter"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar Fontes"
                  variant="standard"
                  type="search"
                  autoComplete="off"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 170 }}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    sx: {
                      pl: 1,
                    },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
          </Grid>
          <Grid>
            <Typography variant="h6" component="h2" color={"primary"}>
              Relação de documentos:
            </Typography>
          </Grid>
          <Grid
            data-testid="gridMarcarDesmarcar"
            container
            justifyContent="space-between"
            sx={{ my: 1 }}
          >
            <Typography sx={{ whiteSpace: "pre-line" }}>
              Clique para marcar/desmarcar
            </Typography>
            <Button
              size="small"
              onClick={handleSelect(allSelected ? "clear" : "all")}
              color="primary"
            >
              {allSelected ? "Desmarcar todos" : "Selecionar tudo"}
            </Button>
          </Grid>
          <List dense sx={{ maxHeight: "29vh", overflowY: "auto" }}>
            {externalFonts
              ?.filter((f) =>
                data.filter
                  ? f.value.name
                      .toLocaleLowerCase()
                      .includes(data.filter.toLocaleLowerCase())
                  : externalFonts
              )
              .map((item) => (
                <ListItem
                  data-testid="listFonts"
                  key={item.key + uuid()}
                  onClick={handleSelect(item.key)}
                  sx={{
                    transition: "all 0.5s ease-in-out",
                    cursor: "pointer",
                    borderRadius: 1,
                    userSelect: "none",
                    ":hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                    },
                  }}
                >
                  <ListItemIcon data-testid="iconFont" sx={{ minWidth: 30 }}>
                    <LabelImportant
                      color={
                        fontsSelected.includes(item.key) ? "warning" : "inherit"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.value.name} />
                </ListItem>
              ))}
          </List>
        </Grid>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          mt: 2,
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Grid item>
          <Button
            sx={{ width: 100, whiteSpace: "nowrap" }}
            onClick={props.onClose}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={
              selectedValue === "fonts"
                ? !fontsSelected.length
                : !modelsSelected
            }
            sx={{ width: 150, whiteSpace: "nowrap" }}
            variant="outlined"
            onClick={() => {
              handleGenerateDossier();
            }}
          >
            {type !== "all" ? "Gerar dossiê" : "Confirmar"}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SelectExternalFonts;
