const EXTERNAL_FONT_NAMES: { [key: string]: string } = {
  mpf: "Mpf",
  cade: "Cade",
  bndes: "Bndes",
  midiaInternet: "Mídia Internet",
  protestoTitulos: "Protesto Títulos",
  regularidadeFgts: "Regularidade Fgts",
  situacaoCadastral: "Situação Cadastral",
  listaTrabalhoEscravo: "Lista Trabalho Escravo",
  certidaoNegativaDebitos: "Certidão Negativa Débitos",
  certidaoDebitosRelativos: "Certidão Débitos Relativos",
  certidaoNegativaInidoneos: "Certidão Negativa Inidôneos",
  certidaoAutuacoesAmbientaisIbama: "Certidão Autuações Ambientais Ibama",
  certidaoNegativaDebitosTributarios: "Certidão Negativa Débitos Tributários",
  certidaoCondenacoesImprobidadeInelegibilidade:
    "Certidão Condenações Improbidade Inelegibilidade",
  certidaoDebitosTrabalhistasMte: "Certidão Débitos Trabalhistas Mte",
  certidaoNegativaCorrecional: "Certidão Negativa Correcional",
  vinculosPoliticos: "Vínculos Políticos",
  sancoesInternacionaisOnu: "Sanções Internacionais Onu",
  sancoesInternacionaisOfac: "Sanções Internacionais Ofac",
  despesasTransparenciaBrasil: "Despesas Transparência Brasil",
  ccm: "CCM - Cadastro de Contribuintes Mobiliários",
  certidaoNegativaDebitosTributariosDividaAtivaEstadoSaoPaulo:
    "Certidão Negativa de Débitos Tributários de São Paulo",
  sicaf: "Sicaf",
  certidaoUnicoDistribuidor: "Certidão Único Distribuidor",
  certidaoFalencia: "Certidão Falência",
  declaracaoCadastral: "Declaração Cadastral",
  certidaoPessoaNaoInscritaCadastroIcmsSp:
    "Certidão Pessoa não Inscrita no Cadastro ICMS SP",
  registroPessoaFisica: "Registro Pessoa Física",
  certidaoDistribuicaoAcoesTrabalhistas:
    "Certidão de Distribuição de Ações Trabalhistas",
  certidaoDistribuicaoCivel: "Certidão de Distribuição Cível",
  certidaoAntecedentesCriminais: "Certidão de Atencedentes Criminais",
  consultaConsolidadaFundoCVM: "Consulta Consolidada de Fundo - CVM",
  certidaoDistribuicaoAcoesCiveisJusticaFederal:
    "Certidão de Distribuição de Ações Cíveis Justiça Federal",
  procuradoriaGeralMG: "Procuradoria Geral Estadual - Minas Gerais",
  procuradoriaGeralSP: "Procuradoria Geral Estadual - São Paulo",
  procuradoriaGeralRJ: "Procuradoria Geral Estadual - Rio de Janeiro",
  procuradoriaGeralPR: "Procuradoria Geral Estadual - Paraná",
  procuradoriaGeralRS: "Procuradoria Geral Estadual - Rio Grande do Sul",
  procuradoriaGeralPE: "Procuradoria Geral Estadual - Pernambuco",
  procuradoriaGeralBA: "Procuradoria Geral Estadual - Bahia",
  procuradoriaGeralMS: "Procuradoria Geral Estadual - Mato Grosso do Sul",
  procuradoriaGeralCE: "Procuradoria Geral Estadual - Ceará",
  procuradoriaGeralMA: "Procuradoria Geral Estadual - Maranhão",
  procuradoriaGeralDF: "Procuradoria Geral Estadual - Distrito Federal",
  procuradoriaGeralMT: "Procuradoria Geral Estadual - Mato Grosso",
  procuradoriaGeralPA: "Procuradoria Geral Estadual - Pará",
  procuradoriaGeralPB: "Procuradoria Geral Estadual - Paraíba",
  procuradoriaGeralRN: "Procuradoria Geral Estadual - Rio Grande do Norte",
  procuradoriaGeralRO: "Procuradoria Geral Estadual - Rondônia",
  procuradoriaGeralSC: "Procuradoria Geral Estadual - Santa Catarina",
  certidaoDistribuicaoCivelTRF1: "Certidão de Distribuição Cível - TRF1",
  certidaoDistribuicaoCivelTRF2: "Certidão de Distribuição Cível - TRF2",
  certidaoDistribuicaoCivelTRF3: "Certidão de Distribuição Cível - TRF3",
  certidaoDistribuicaoCivelTRF4: "Certidão de Distribuição Cível - TRF4",
  certidaoDistribuicaoCivelTRF5: "Certidão de Distribuição Cível - TRF5",
  certidaoDistribuicaoCivelTRF6: "Certidão de Distribuição Cível - TRF6",
  certidaoEstadualTributariaAL: "Certidão Estadual Tributária - Alagoas",
  certidaoEstadualTributariaGO: "Certidão Estadual Tributária - Goiás",
  certidaoEstadualTributariaAM: "Certidão Estadual Tributária - Amazonas",
  certidaoEstadualTributariaRJ: "Certidão Estadual Tributária - Rio de Janeiro",
  certidaoEstadualTributariaRS:
    "Certidão Estadual Tributária - Rio Grande do Sul",
  certidaoEstadualTributariaPA: "Certidão Estadual Tributária - Pará",
  certidaoEstadualTributariaES: "Certidão Estadual Tributária - Espírito Santo",
  certidaoEstadualTributariaMA: "Certidão Estadual Tributária - Maranhão",
  certidaoEstadualTributariaRO: "Certidão Estadual Tributária - Rondônia",
  certidaoEstadualTributariaPI: "Certidão Estadual Tributária - Piauí",
  certidaoEstadualTributariaPE: "Certidão Estadual Tributária - Pernambuco",
  certidaoEstadualTributariaPB: "Certidão Estadual Tributária - Paraíba",
  certidaoEstadualTributariaMT: "Certidão Estadual Tributária - Mato Grosso",
  certidaoEstadualTributariaCE: "Certidão Estadual Tributária - Ceará",
  certidaoEstadualTributariaBA: "Certidão Estadual Tributária - Bahia",
  certidaoEstadualTributariaMS:
    "Certidão Estadual Tributária - Mato Grosso do Sul",
  certidaoEstadualTributariaRN:
    "Certidão Estadual Tributária - Rio Grande do Norte",
  certidaoTributariaMunicipalPalmas: "Certidão Tributária Municipal - Palmas",
  certidaoTributariaMunicipalSaoPaulo:
    "Certidão Tributária Municipal- São Paulo",
  certidaoTributariaMunicipalAracaju: "Certidão Tributária Municipal - Aracaju",
  certidaoTributariaMacapa: "Certidão Tributária - Macapá",
  certidaoTributariaSaoLuis: "Certidão Tributária - São Luís",
  certidaoTributariaSaoPaulo: "Certidão Tributária - São Paulo",
  certidaoTributariaBeloHorizonte: "Certidão Tributária - Belo Horizonte",
  certidaoTributariaSalvador: "Certidão Tributária - Salvador",
  certidaoTributariaPortoAlegre: "Certidão Tributária - Porto Alegre",
  certidaoTributariaPortoVelho: "Certidão Tributária - Porto Velho",
  certidaoTributariaRioBranco: "Certidão Tributária - Rio Branco",
  certidaoTributariaManaus: "Certidão Tributária - Manaus",
  certidaoTributariaMaceio: "Certidão Tributária - Maceió",
  certidaoTributariaFlorianopolis: "Certidão Tributária - Florianópolis",
  certidaoTributariaJoaoPessoa: "Certidão Tributária - João Pessoa",
  certidaoTributariaCuiaba: "Certidão Tributária - Cuiabá",
  certidaoTributariaFortaleza: "Certidão Tributária - Fortaleza",
  certidaoTribunalCuritiba: "Certidão Tribunal - Curitiba",
  certidaoDistribuicaoAcoesCriminaisTJSP:
    "Certidão Distribuições Ações Criminais TJSP",
  certidaoAcoesJudiciaisCivelGrau1Tjro:
    "Certidão de Ações Judiciais Cível Grau1 TJRO",
  certidaoAcoesJudiciaisCriminalGrau1Tjro:
    "Certidão de Ações Judiciais Criminal Grau1 TJRO",
  certidaoAcoesJudiciaisFalenciaGrau1Tjro:
    "Certidão de Ações Judiciais Falência Grau1 TJRO",
  certidaoAcoesJudiciaisCivelCriminalGrau1Tjro:
    "Certidão de Ações Judiciais Cível Criminal Grau1 TJRO",
  certidaoMititanciaGrau1Tjro: "Certidão de Mitigância Grau1 TJRO",
  certidaoAcoesCiveisCriminaisGeraisGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais Gerais Grau2 TJRO",
  certidaoAcoesCiveisCriminaisLei36862013Grau2Tjro:
    "Certidão de Ações Cíveis e Criminais Lei 3686/2013 Grau2 TJRO",
  certidaoAcoesCiveisGrau2Tjro: "Certidão de Ações Cíveis Grau2 TJRO",
  certidaoAcoesCriminaisGrau2Tjro: "Certidão de Ações Criminais Grau2 TJRO",
  certidaoAcoesCiveisCriminaisGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais Grau2 TJRO",
  certidaoAcoesCiveisCriminaisOriginariasEleitoraisGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais Originárias Eleitorais Grau2 TJRO",
  certidaoAcoesCiveisCriminaisRes156CnjGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais Resolução 156 CNJ Grau2 TJRO",
  certidaoAcoesCiveisCriminaisLei29282012Grau2Tjro:
    "Certidão de Ações Cíveis e Criminais Lei 2928/2012 Grau2 TJRO",
  certidaoAcoesCiveisCriminaisAdimissaoConcursoGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais Admissão Concurso Grau2 TJRO",
  certidaoAcoesCriminaisPorteArmaGrau2Tjro:
    "Certidão de Ações Criminais Porte de Arma Grau2 TJRO",
  certidaoAcoesCiveisCriminaisInscricaoOabGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais Inscrição OAB Grau2 TJRO",
  certidaoAcoesCiveisCriminaisPmBombeirosGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais PM Bombeiros Grau2 TJRO",
  certidaoAcoesCiveisCriminaisAposentadoriaGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais Aposentadoria Grau2 TJRO",
  certidaoAcoesCiveisCriminaisDetranSemtranGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais DETRAN SEMTRAN Grau2 TJRO",
  certidaoAcoesCiveisCriminaisServidoresPublicosGrau2Tjro:
    "Certidão de Ações Cíveis e Criminais Servidores Públicos Grau2 TJRO",
  sancoesCnep: "Sancoes Cnep",
  sancoesCepim: "Sancoes Cepim",
  sancoesCeis: "Sancoes Ceis",
  sancoesCeaf: "Sancoes Ceaf",
  acordosLeniencia: "Acordos Leniencia",
  coletorServidoresPublicosPortalTransparencia:
    "Busca por beneficios no Portal da Transparência",
  coletorBeneficiosPortalTransparencia:
    "Busca por servidores públicos no Portal da Transparência.",
  coletorRenunciasFiscaisPortalTransparencia:
    "Busca por renuncias fiscais no site do Portal da Transparência",
  coletorLicitacoesPortalTransparencia:
    "Busca por licitacoes no Portal da Transparência",
  coletorPepPortalTransparencia:
    "Busca por Pessoas Expostas Politicamente (PEP) no Portal da Transparência",
  coletorGastosCartaoPagamentoPortalTransparencia:
    "Gastos no cartao de Pagamento no Portal da Transparência",
  consultaImoveisFuncionaisPortalTransparencia:
    "Busca por Imóveis funcionais no Portal da Transparência",
  buscarSerasa: "Busca serasa",
  certidaoAcoesCiveisLei29282012Grau2Tjro:
    "Certidâo Acões Cíveis da Lei 2928/2012 Grau2 Tjro",
  certidaoAcoesCiveisLei36862013Grau2Tjro:
    "Certidâo Acões Cíveis da Lei 3686/2013 Grau2 Tjro",

  devedoresPGFN:
    "PGFN - Busca por devedores na Procuradoria Geral da Fazenda Nacional",
  contasIrregularesTCU: "TCU - Coleta certidão negativa de contas irregulares",
  contasIrregularesEleitoralTCU:
    "TCU - Coleta certidão negativa de contas irregulares com implicação eleitoral",
  inabilitadosTCU: "TCU - Coleta certidao negativa Inabilitados",
  inabilitadosBacen:
    "BACEN - Busca por Inabilitados e Proibidos de Atuar no Banco Central do Brasil",
};

const MASK_CPF = "999.999.999-99";
const MASK_CNPJ = "99.999.999/9999-99";
const MASK_IDENTY = "99.999.999-9";

export { EXTERNAL_FONT_NAMES, MASK_CNPJ, MASK_CPF, MASK_IDENTY };
