import api from "services/api";
import { INotificationViewModel, IResponse, IVoidResponse } from "viewModels";

const getAll = (page?: number, take?: number) =>
  new Promise<IResponse<INotificationViewModel[]>>((resolve, reject) => {
    api
      .get("notification/get-all", {
        params: { page, take },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const markAsRead = (id: string) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`notification/mark-as-read/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const markAllAsRead = () =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put("notification/mark-all-as-read")
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const remove = (id: string) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .delete(`notification/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const removeAll = () =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .delete("notification")
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const NotificationController = {
  getAll,
  remove,
  removeAll,
  markAsRead,
  markAllAsRead,
};
