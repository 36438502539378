import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Modal, { ModalProps } from "..";
import { Cancel, LabelImportant } from "@mui/icons-material";
import { IAvailableExternalFontViewModel, IDossierViewModel } from "viewModels";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ptBR } from "@mui/x-date-pickers/locales";
import { Container } from "./styles";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { GenerateAgainType, SearchType } from "types/enums";
import { IGenerateAgainInputModel } from "inputModels";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DossierController } from "controllers";
import { useAlert, useDossier } from "contexts";
import { v4 as uuid } from "uuid";

interface GenerateAgainProps extends Omit<ModalProps, "onSelect"> {
  onSelect?: (selected: string[]) => void;
  dossier: IDossierViewModel;
  externalFonts: IAvailableExternalFontViewModel[];
}

const GenerateAgain: React.FC<GenerateAgainProps> = ({
  onSelect,
  dossier,
  externalFonts,
  ...props
}: GenerateAgainProps) => {
  const MIN_DATE = dayjs().add(1, "day").toDate();
  const MAX_DATE = dayjs().add(1, "year").toDate();
  const alert = useAlert();
  const { setDossiers } = useDossier();
  const [selected, setSelected] = useState<IAvailableExternalFontViewModel[]>(
    dossier.selectedExternalFonts
  );
  const [generateAgain, setGenerateAgain] = useState<boolean>(
    dossier.generateAgain ?? true
  );
  const [generateType, setGenerateType] = useState<GenerateAgainType>(
    dossier.generateType ? dossier.generateType : GenerateAgainType.WEEKLY
  );
  const { colors } = useTheme();
  const [defaultValues] = useState({
    dateToGenerateAgain: dossier.dateToGenerateAgain
      ? (dayjs(dossier.dateToGenerateAgain) as unknown as Date)
      : (dayjs().add(3, "day") as unknown as Date),
    daysToRegenerateDossier: dossier.daysToRegenerateDossier?.toString() ?? "",
  });

  const schema = yup.object().shape({
    dateToGenerateAgain: yup
      .date()
      .typeError("Data inválida")
      .required("Campo obrigatório")
      .min(MIN_DATE, "Data fora do prazo mínimo")
      .max(MAX_DATE, "Data fora do prazo máximo"),
    daysToRegenerateDossier: yup
      .string()
      .test("isRequired", "Campo obrigatório", (value) =>
        generateType === GenerateAgainType.CUSTOM ? !!value : true
      ),
  });

  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
    resolver: yupResolver(schema),
  });

  const allSelected = useMemo(
    () => (externalFonts ?? [])?.length === selected?.length,
    [externalFonts, selected]
  );

  useEffect(() => {
    setSelected(dossier.selectedExternalFonts);
    setGenerateAgain(dossier.generateAgain ?? true);
    setGenerateType(
      dossier.generateType ? dossier.generateType : GenerateAgainType.WEEKLY
    );
    setValue(
      "dateToGenerateAgain",
      dossier.dateToGenerateAgain
        ? (dayjs(dossier.dateToGenerateAgain) as unknown as Date)
        : (dayjs().add(3, "day") as unknown as Date)
    );
    if (dossier.generateType === GenerateAgainType.CUSTOM)
      setValue(
        "daysToRegenerateDossier",
        dossier.daysToRegenerateDossier?.toString() ?? ""
      );
  }, [
    dossier.selectedExternalFonts,
    dossier.generateAgain,
    dossier.dateToGenerateAgain,
    setValue,
    dossier.generateType,
    dossier.daysToRegenerateDossier,
  ]);

  const handleSelect =
    (key: string, item?: IAvailableExternalFontViewModel) => () => {
      if (key === "all") setSelected(externalFonts);
      else if (key === "clear") setSelected([]);
      else
        setSelected((prev) => {
          const newSelected = [...prev];
          const idx = newSelected.findIndex((f) => f.key === key);
          if (idx !== -1) newSelected.splice(idx, 1);
          else {
            if (item) newSelected.push(item);
          }
          return newSelected;
        });
    };

  const data = watch();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Number(data.daysToRegenerateDossier) === 7) {
        setGenerateType(GenerateAgainType.WEEKLY);
        setValue("daysToRegenerateDossier", "");
      } else if (Number(data.daysToRegenerateDossier) === 30) {
        setGenerateType(GenerateAgainType.MONTHLY);
        setValue("daysToRegenerateDossier", "");
      } else if (Number(data.daysToRegenerateDossier) === 180) {
        setGenerateType(GenerateAgainType.EVERY_SIX_MONTHS);
        setValue("daysToRegenerateDossier", "");
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [data.daysToRegenerateDossier, setValue]);

  const handleSave = async () => {
    const daysToRegenerateDossier =
      generateType === GenerateAgainType.CUSTOM
        ? Number(data.daysToRegenerateDossier)
        : generateType === GenerateAgainType.WEEKLY
        ? 7
        : generateType === GenerateAgainType.MONTHLY
        ? 30
        : 180;
    const dateToGenerateAgain = dayjs(data.dateToGenerateAgain)
      .startOf("day")
      .toDate();
    const input: IGenerateAgainInputModel = {
      dossierId: dossier.id,
      dateToGenerateAgain,
      selectedExternalFonts: selected.map((s) => s.key),
      generateAgain: generateAgain,
      generateType: generateType,
      daysToRegenerateDossier,
    };

    DossierController.dossierGenerateAgain(input)
      .then(async () => {
        setDossiers((prev) => {
          const newDossiers = [...prev];
          const idx = newDossiers.findIndex((d) => d.id === dossier.id);
          if (idx !== -1) {
            newDossiers[idx].dateToGenerateAgain = input.dateToGenerateAgain;
            newDossiers[idx].generateAgain = input.generateAgain;
            newDossiers[idx].generateType = input.generateType;
            newDossiers[idx].daysToRegenerateDossier =
              input.daysToRegenerateDossier;
            newDossiers[idx].selectedExternalFonts = selected;
          }
          return newDossiers;
        });
        await alert.show({
          type: "alert",
          title: "Sucesso:",
          description: "Monitoramento salvo com sucesso.",
          timeout: 3000,
        });
        props.onClose();
      })
      .catch(async () => {
        await alert.show({
          type: "error",
          title: "Erro:",
          description: "Não foi possível salvar o monitoramento.",
          retry: { callback: handleSave, label: "Tentar novamente" },
        });
      });
  };

  const handlePauseOrMonitoring = async (status: boolean) => {
    setGenerateAgain(status);
    const daysToRegenerateDossier =
      generateType === GenerateAgainType.CUSTOM
        ? Number(data.daysToRegenerateDossier)
        : generateType === GenerateAgainType.WEEKLY
        ? 7
        : generateType === GenerateAgainType.MONTHLY
        ? 30
        : 180;
    const dateToGenerateAgain = dayjs(data.dateToGenerateAgain)
      .startOf("day")
      .toDate();
    const input: IGenerateAgainInputModel = {
      dossierId: dossier.id,
      dateToGenerateAgain,
      selectedExternalFonts: selected.map((s) => s.key),
      generateAgain: status,
      generateType: generateType,
      daysToRegenerateDossier,
    };

    DossierController.dossierGenerateAgain(input)
      .then(async () => {
        setDossiers((prev) => {
          const newDossiers = [...prev];
          const idx = newDossiers.findIndex((d) => d.id === dossier.id);
          if (idx !== -1) {
            newDossiers[idx].dateToGenerateAgain = input.dateToGenerateAgain;
            newDossiers[idx].generateAgain = input.generateAgain;
            newDossiers[idx].generateType = input.generateType;
            newDossiers[idx].daysToRegenerateDossier =
              input.daysToRegenerateDossier;
            newDossiers[idx].selectedExternalFonts = selected;
          }
          return newDossiers;
        });
        await alert.show({
          type: "alert",
          title: "Sucesso:",
          description: "Monitoramento salvo com sucesso.",
          timeout: 3000,
        });
      })
      .catch(async () => {
        await alert.show({
          type: "error",
          title: "Erro:",
          description: "Não foi possível salvar o monitoramento.",
          retry: { callback: handleSave, label: "Tentar novamente" },
        });
      });
  };

  return (
    <Modal
      {...props}
      data-testid="generate-again-mock"
      sx={{
        position: "relative",
        width: "26rem",
        height: "48rem",
        maxHeight: "86vh",
        overflowX: "hidden",
        overflowY: "auto",
        p: 1,
        "@media (max-width: 375px)": {
          width: "23rem",
          maxHeight: "100vh",
          height: "50rem",
        },
      }}
    >
      <Grid container justifyContent="space-between" padding="0.5rem 0">
        <Typography variant="h5" component="h2" color="primary">
          Monitoramento
        </Typography>
        <IconButton size="small" onClick={props.onClose} color="error">
          <Cancel />
        </IconButton>
      </Grid>
      <Container>
        <Grid>
          <Typography
            variant="h6"
            fontSize={"1rem"}
            component="h2"
            color={colors.secondary}
          >
            Informe a data em que o dossiê deve ser gerado novamente:
          </Typography>
          <Controller
            name="dateToGenerateAgain"
            control={control}
            // disabled={dossier.generateAgain !== undefined}
            render={({ field: { onChange, value, ...rest } }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={
                  ptBR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <DateField
                  fullWidth
                  size="small"
                  format="DD/MM/YYYY"
                  value={value}
                  FormHelperTextProps={{ sx: { color: "#d32f2f" } }}
                  onChange={onChange}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  helperText={errors.dateToGenerateAgain?.message}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: errors.dateToGenerateAgain?.message
                          ? "#d32f2f"
                          : "#0000003b",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: errors.dateToGenerateAgain?.message
                        ? "#d32f2f"
                        : "#1e0b0b99",
                    },
                  }}
                  {...rest}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid padding="1rem 0">
          <Typography
            variant="h6"
            fontSize={"1rem"}
            component="h2"
            color={colors.secondary}
          >
            Selecione as fontes para serem geradas:
          </Typography>
          <Grid container justifyContent="space-between" sx={{ my: 1 }}>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              Clique para marcar/desmarcar
            </Typography>
            <Button
              size="small"
              onClick={handleSelect(allSelected ? "clear" : "all")}
              color="primary"
            >
              {allSelected ? "Desmarcar todos" : "Selecionar tudo"}
            </Button>
          </Grid>
          <List
            dense
            sx={{
              maxHeight: "27vh",
              overflowY: "auto",
              border: "1px solid #BBB",
              borderRadius: "0.1rem",
              padding: "0.5rem",
            }}
          >
            {externalFonts
              .filter((f) =>
                dossier.document?.length === 14
                  ? f.value.type !== SearchType.CPF
                  : f.value.type !== SearchType.CNPJ
              )

              ?.map((item) => (
                <ListItem
                  key={item.key + uuid()}
                  onClick={handleSelect(item.key, item)}
                  sx={{
                    transition: "all 0.5s ease-in-out",
                    cursor: "pointer",
                    borderRadius: "1px",
                    userSelect: "none",
                    ":hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <LabelImportant
                      color={
                        selected?.some((s) => s.key === item.key)
                          ? "warning"
                          : "inherit"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.value.name} />
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid padding="1rem 0">
          <Typography
            variant="h6"
            fontSize={"1rem"}
            component="h2"
            color={colors.secondary}
          >
            Repetir a partir da data escolhida:
          </Typography>
          <Grid>
            <FormControl>
              <RadioGroup
                onChange={(e) => {
                  setGenerateType(Number(e.target.value) as GenerateAgainType);
                  clearErrors();
                  setValue("daysToRegenerateDossier", "");
                }}
                value={generateType}
              >
                <Grid
                  sx={{
                    maxHeight: "20vh",
                    overflowY: "auto",
                    border: "1px solid #BBB",
                    borderRadius: "0.1rem",
                    padding: "0.5rem",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <Grid>
                    <FormControlLabel
                      value={GenerateAgainType.WEEKLY}
                      control={<Radio />}
                      label={
                        <Typography
                          variant="h6"
                          fontSize={"1rem"}
                          component="h2"
                          color={colors.secondary}
                          minHeight={"2.2rem"}
                          alignItems="center"
                          display="flex"
                        >
                          Semanalmente
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={GenerateAgainType.MONTHLY}
                      control={<Radio />}
                      label={
                        <Typography
                          variant="h6"
                          fontSize={"1rem"}
                          component="h2"
                          color={colors.secondary}
                          minHeight={"2.2rem"}
                          alignItems="center"
                          display="flex"
                        >
                          Mensalmente
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid>
                    <FormControlLabel
                      value={GenerateAgainType.EVERY_SIX_MONTHS}
                      control={<Radio />}
                      label={
                        <Typography
                          variant="h6"
                          fontSize={"1rem"}
                          component="h2"
                          color={colors.secondary}
                          minHeight={"2.2rem"}
                          display="flex"
                          alignItems="center"
                        >
                          A cada 6 meses
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={GenerateAgainType.CUSTOM}
                      control={<Radio />}
                      label={
                        <Typography
                          variant="h6"
                          fontSize={"1rem"}
                          component="h2"
                          color={colors.secondary}
                          minHeight={"2.2rem"}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-around"
                        >
                          Após
                          <Controller
                            name="daysToRegenerateDossier"
                            control={control}
                            render={({
                              field: { onChange, value, ...rest },
                            }) => (
                              <TextField
                                data-testid="inputGenerateAgainCustom"
                                size="small"
                                style={{
                                  maxWidth: "2.75rem",
                                  minWidth: "2.75rem",
                                  margin: "0 0.5rem",
                                  fontSize: "0.8rem",
                                }}
                                inputProps={{ maxLength: 3 }}
                                onChange={onChange}
                                disabled={
                                  generateType !== GenerateAgainType.CUSTOM
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor:
                                        errors.daysToRegenerateDossier
                                          ? "#d32f2f"
                                          : "#0000003b",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: errors.daysToRegenerateDossier
                                      ? "#d32f2f"
                                      : "#1e0b0b99",
                                  },
                                }}
                                onKeyDown={(e) => {
                                  if (
                                    isNaN(Number(e.key)) &&
                                    ![
                                      "Backspace",
                                      "Delete",
                                      "ArrowLeft",
                                      "ArrowRight",
                                    ].includes(e.code)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                value={value}
                                {...rest}
                              />
                            )}
                          />{" "}
                          dias
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          {errors.daysToRegenerateDossier && (
            <Typography
              variant="body1"
              display="flex"
              justifyContent="center"
              color="error"
            >
              Campo obrigatório
            </Typography>
          )}
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Grid item>
            {dossier.generateAgain !== undefined && (
              <Button
                sx={{ width: 180, whiteSpace: "nowrap" }}
                variant="outlined"
                color={generateAgain ? "inherit" : "warning"}
                onClick={() => {
                  if (!generateAgain) {
                    handleSubmit(() =>
                      handlePauseOrMonitoring(!generateAgain)
                    )();
                  } else {
                    handlePauseOrMonitoring(!generateAgain);
                  }
                }}
              >
                {generateAgain
                  ? "Pausar monitoramento"
                  : "Retomar monitoramento"}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              disabled={selected?.length === 0}
              variant="contained"
              sx={{ width: 140, whiteSpace: "nowrap" }}
              onClick={handleSubmit(handleSave)}
              type="button"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default GenerateAgain;
