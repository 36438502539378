import { Grid, Button, IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from 'react';
import { Cancel } from "@mui/icons-material";
import { Tooltip } from '@mui/material';
import { Modal } from "components";
import { Container } from "./style";

interface TermsConditionsProps{
  isOpen: boolean,
  onClose: () => void,
  onConfirm: () => void
}

export const TermsConditions: React.FC<TermsConditionsProps> = ({
  isOpen,
  onClose,
  onConfirm
}: TermsConditionsProps) => {
const [isDisabled, setIsDisabled] = useState(true);
  const [ref, setRef] = useState<HTMLDivElement>();

  const handleScroll = () => {
    if (ref) {
      const positionScroll = Math.round(ref.scrollTop + ref.clientHeight);
      const heightScroll = ref.scrollHeight;
      if (positionScroll >= heightScroll) {
        setIsDisabled(false);
      }
    }
  };

  const containerRef = useCallback((currentRef: HTMLDivElement) => {
    if (currentRef !== null) setRef(currentRef);
  }, []);

  useEffect(() => {
    if (ref) {
      ref.addEventListener('scroll', handleScroll);
    }
  });

  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal 
      open={isOpen}
      onClose={onClose}
    >
      <Grid container justifyContent="space-between" padding="0.5rem 0">
        <Typography variant="h5" component="h2" color="primary">
          Política de privacidade
        </Typography>
        <IconButton size="small" onClick={()=>onClose()} color="error">
          <Cancel />
        </IconButton>
      </Grid>
      <Container ref={containerRef}>
        <h1>Aceite dos Termos</h1>
        <p>
          O presentes termo e condições de uso visam regular a utilização por você, usuário, de nossos serviços.
        </p>
        <p>
          Ao utilizar nossos serviços, o usuário aceita e concorda com todos os termos e condições expostas que se encontram vigentes na data, bem como Política de Privacidade associada.
        </p>
        <p>
          Reservamo-nos o direito de atualizar, modificar ou substituir qualquer parte destes Termos a qualquer momento. É sua responsabilidade verificar periodicamente os termos atualizados. O uso contínuo de nossos serviços após a publicação de quaisquer alterações constitui aceitação dessas alterações.
        </p>
        <li><strong>Tratamento de Dados</strong></li>
        <p>
          A sua privacidade e o sigilo de suas informações são extremamente importantes. A Legal Audit adota todos os cuidados necessários para garantir que seus dados sejam protegidos, bem como o tratamento adequado de seus dados pessoais.
        </p>
        <p>
          Nós nos comprometemos a seguir e cumprir as normas previstas na Lei Geral de Proteção de Dados e realizar o tratamento de seus dados em conformidade com as legislações aplicáveis. 
        </p>
        <li><strong>Privacidade</strong></li>
        <p>
          Para a utilização da plataforma e suas funcionalidades, é necessário realizar o cadastro. Os dados solicitados para o cadastro são: CPF ou CNPJ, nome, e-mail e senha.
        </p>
        <p>
          O usuário é responsável pela veracidade e atualização dos dados cadastrais informados. É importante que eles estejam corretos e atualizados para que não ocorram problemas de acesso à Plataforma. Quaisquer prejuízos havidos em decorrência da inclusão de informações inverídicas ou desatualizadas, na Plataforma, será de sua única e exclusiva responsabilidade.
        </p>
        <p>
          Cada usuário é igualmente responsável pela guarda, sigilo e utilização do login e senhas cadastrados, que possuem caráter pessoal e intransferível. Deste modo, nunca compartilhe seus dados de acesso com outras pessoas, pois qualquer prejuízo sofrido em decorrência de tal divulgação será de sua responsabilidade.
        </p>
        <li><strong>Finalidade do Tratamento</strong></li>
        <p>
          O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades: 
        </p>
        <ul>
          <li>
            Possibilitar que a Legal Audit identifique e entre em contato com o Titular para fins de relacionamento comercial; 
          </li>
          <li>
            Possibilitar que a Legal Audit elabore contratos comerciais e emita cobranças contra o Titular; 
          </li>
          <li>
            Possibilitar a análise diagnóstica do cliente afim de proporcionar o atendimento mais adequado; 
          </li>
          <li>
            Possibilitar o envio ou fornecimento de seus produtos e serviços ao Titular, de forma remunerada ou gratuita; 
          </li>
          <li>
            Possibilitar que a Legal Audit estruture, teste, promova e faça propaganda de produtos e serviços, personalizados ou não ao perfil do Titular; 
          </li>
          <li>
            Possibilitar a utilize de tais dados em Pesquisas de Mercado, bem como inscrição, divulgação, premiação dos interessados participantes de Eventos, treinamentos ou Concursos, elaboração de catálogos e curadoria de autores; e,
          </li>
          <li>
            Possibilitar a elaboração de relatórios e emissão de produtos e serviços.
          </li>
        </ul>
        <li><strong>Compartilhamento de Dados</strong></li>
        <p>
          A Legal Audit poderá compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste termo e cumprimento do contrato, observados os princípios e as garantias estabelecidas pela Lei nº 13.709/18.
        </p>
        <li><strong>Medidas de Segurança</strong></li>
        <p>
          A Legal Audit responsabiliza-se pela manutenção de medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito. 
        </p>
        <p>
          Em conformidade ao art. 48 da Lei nº 13.709/18, o Controlador comunicará ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao Titular.
        </p>
        <li><strong>Término do Tratamento dos Dados</strong></li>
        <p>
          A Legal Audit manterá e tratará os dados pessoais do Titular durante todo o período em que este mantiver sua conta ativa, podendo armazena-los pelo período legal permitido e necessários para o cumprimento de obrigações legais ou exercício regular de seu direito.
        </p>
        <p>
          Os dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser mantidos por período indefinido.
        </p>
        <p>
          O Titular poderá solicitar via e-mail, a qualquer momento, que sejam eliminados os dados pessoais não anonimizados do Titular, cuja base legal seja o consentimento. O Titular fica ciente de que poderá ser inviável continuar o fornecimento de produtos ou serviços ao Titular a partir da eliminação dos dados pessoais.
        </p>
        <li><strong>Direitos do Titular</strong></li>
        <p>
          O Titular tem direito a obter da Legal Audit, em relação aos dados por ele tratados, a qualquer momento e mediante requisição:
        </p>
        <ul>
          <li>
            Confirmação da existência de tratamento;
          </li>
          <li>
            Acesso aos dados;
          </li>
          <li>
            Correção de dados incompletos, inexatos ou desatualizados;
          </li>
          <li>
            Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709/18;
          </li>
          <li>
            Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e industrial, de acordo com a regulamentação do órgão controlador;
          </li>
          <li>
            Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;
          </li>
          <li>
            Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709/18;
          </li>
          <li>
            Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;
          </li>
          <li>
            Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e
          </li>
          <li>
            Revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709/18.
          </li>
        </ul>
        <li><strong>Limitação de responsabilidade</strong></li>
        <p>
          A Legal Audit <span>não será responsável:</span>
        </p>
        <ul>
          <li>
            Por eventuais erros e/ou inconsistências na transmissão de dados da rede, bem como relacionados à qualidade ou disponibilidade da conexão de internet capazes de obstar o adequado recebimento de informações;
          </li>
          <li>
            Por dados desatualizados, incompletos e/ou inverídicos eventualmente apresentados por meio da Plataforma;
          </li>
          <li>
            Por eventuais danos resultantes do uso da Plataforma em desacordo com estes Termos;
          </li>
          <li>
            Pelos danos e prejuízos de toda natureza decorrentes do conhecimento que terceiros não autorizados possam ter a dados disponibilizados por meio da Plataforma, em decorrência de falha exclusivamente relacionada ao usuário ou a terceiros que fujam ao controle razoável da Legal Audit; e/ou
          </li>
          <li>
            Pela presença de vírus ou demais elementos nocivos na Plataforma, capazes de causar alterações em seus sistemas informáticos (software e hardware) ou em documentos eletrônicos armazenados em seus sistemas informáticos.
          </li>
        </ul>
        <li><strong>Informações de Contato</strong></li>
        <p>
          Eventuais questionamentos ou requisição, o titular de dados poderá entrar em contato com o DPO pelo e-mail contato@legalcontrol.com.br.
        </p>
      </Container>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Tooltip arrow placement="top" title={isDisabled ? "Leia todo os termos de políticas de privacidade" : ""} >
          <div>
            <Button disabled={isDisabled} onClick={()=>handleConfirm()}>Aceitar</Button>
          </div>
        </Tooltip>
      </div>
    </Modal >
  );
};