import { Button, TextField } from "@mui/material";
import { Modals } from "components";
import { useAlert, useApp, useAuth } from "contexts";
import { AuthController } from "controllers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Background from "../../assets/png/background.jpg";
import { Container } from "./styles";
import { Input } from "components/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

const Home: React.FC = () => {
  const [expectedCode, setExpectedCode] = useState("");
  const { signIn, logged } = useAuth();
  const confirm = useAlert();
  const [isOpenRecoverPasswordModal, setIsOpenRecoverPasswordModal] =
    useState(false);
  const { loading, setLoading } = useApp();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [validRecaptcha, setValidRecaptcha] = useState(false);
  const [defaultValues] = useState({
    email: "",
    password: "",
  });

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .required("O campo e-mail é obrigatório")
      .email("Digite um e-mail válido"),
    password: yup
      .string()
      .required("O campo senha é obrigatório")
      .min(6, "Senha deve ter no mínimo 6 dígitos"),
  });
  const {
    formState: { errors },
    control,
  } = useForm({ defaultValues, resolver: yupResolver(loginSchema) });

  const navigate = useNavigate();

  const handleLogin = async () => {
    if (loading) return;
    if (!email) {
      await confirm.show({
        type: "error",
        title: "Falha ao logar",
        description: "Informe um e-mail válido antes de continuar.",
        retry: {
          label: "Ok",
        },
        cancel: {
          show: false,
        },
      });
      return;
    }
    if (!password) {
      await confirm.show({
        type: "error",
        title: "Falha ao logar",
        description: "Senha informada é inválida.",
        retry: {
          label: "Ok",
        },
        cancel: {
          show: false,
        },
      });
      return;
    }

    setLoading(true);
    AuthController.login({ email, password })
      .then(signIn)
      .catch(async () => {
        setLoading(false);
        await confirm.show({
          type: "error",
          title: "Falha ao logar",
          description: "Verifique o email e a senha e tente novamente.",
          retry: {
            callback: handleLogin,
          },
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (logged) navigate("/search");
  }, [navigate, logged]);

  useEffect(() => {
    if (window.location.hostname !== "app.legalaudit.com.br") {
      setValidRecaptcha(true);
    }
  }, []);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "whitesmoke",
          height: "100vh",
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="login-container">
          <h1>Legal Audit</h1>
          <strong>Login</strong>
          <p>Bem-vindo ao sistema!</p>
          <form
            style={{
              minWidth: 250,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
              marginTop: 20,
            }}
          >
            <TextField
              size="small"
              fullWidth
              type="email"
              name="email"
              label="E-mail"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              // size="small"
              // fullWidth
              control={control}
              placeholder="Senha"
              type="password"
              name="password"
              // label="Senha"
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter" && validRecaptcha) handleLogin();
              }}
            />
            {process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                onChange={() => setValidRecaptcha(true)}
              />
            )}
            <Button
              style={{ marginTop: 5 }}
              sx={{ width: "50%" }}
              type="button"
              variant="contained"
              onClick={handleLogin}
              disabled={!validRecaptcha}
            >
              Entrar
            </Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {/* <span style={{
                display: 'flex',
                alignItems: 'center'
              }}>Não possui uma conta? <Button
                data-testid='button-recover-password'
                style={{ textDecoration: 'underline', height: 25 }}
                onClick={() => { navigate('/register') }}
              >
                  Registrar conta
                </Button></span> */}
              <Button
                data-testid="button-recover-password"
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  setIsOpenRecoverPasswordModal(true);
                }}
              >
                Recuperar senha
              </Button>
            </div>
          </form>
        </div>

        <Modals.RecoverPasswordModal
          open={isOpenRecoverPasswordModal}
          typeModal="recoverPassword"
          onCodeChange={(codeChange) => setExpectedCode(codeChange)}
          data={{
            codeExpected: expectedCode,
            contactLabel: "E-mail",
            contactValue: "",
          }}
          onClose={() => setIsOpenRecoverPasswordModal(false)}
        />
      </div>
    </Container>
  );
};

export default Home;
