import { Props } from "props";
import AppProvider, { useApp } from "./app";
import AuthProvider, { useAuth } from "./auth";
import AlertProvider, { useAlert } from "./alert";
import ModelProvider, { useModel } from "./model";
import NotificationProvider, { useNotification } from "./notification";
import DossierProvider, { useDossier } from "./dossier";
import CollaboratorUserProvider, { useCollaboratorUser } from "./collaboratorUser";
import CompanyUserProvider, { useCompanyUser } from "./companyUser";
import LogUserProvider, { useLogUser } from "./logUser";
import LogProvider, { useLog } from "./log";
import RecklessTraderProvider, { useRecklessTrader } from "./recklessTrader";


export { useApp, useAuth, useAlert, useModel, useNotification, useDossier, useCollaboratorUser, useCompanyUser, useLogUser, useLog, useRecklessTrader};

const GlobalContext: React.FC<Props> = ({ children }: Props) => {
  return (
    <AppProvider>
      <AlertProvider>
        <AuthProvider>
          <LogUserProvider>
            <LogProvider>
              <DossierProvider>
                <CompanyUserProvider>
                  <CollaboratorUserProvider>
                    <RecklessTraderProvider>
                        <ModelProvider>
                          <NotificationProvider>{children}</NotificationProvider>
                        </ModelProvider>
                    </RecklessTraderProvider>
                  </CollaboratorUserProvider>
                </CompanyUserProvider>
              </DossierProvider>
            </LogProvider>
          </LogUserProvider>
        </AuthProvider>
      </AlertProvider>
    </AppProvider>
  );
};

export default GlobalContext;
