import { api } from "services";
import { IPrivateIndividualInformationBaseViewModel } from "viewModels";

const GetPrivateIndividual = (document: string) =>
  new Promise<IPrivateIndividualInformationBaseViewModel>((resolve, reject) => {
    api
      .get(`informationbaseprivateindividual/${document}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const PrivateIndividualInformationBaseController = { GetPrivateIndividual }