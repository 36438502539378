import { NotificationController } from "controllers";
import useWebSocket from "hooks/useWebSocket";
import React, { ReactNode, useContext, useEffect, useMemo } from "react";
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useInfiniteQuery,
} from "react-query";
import { INotificationViewModel } from "viewModels";
import { useAlert } from "./alert";
import { useAuth } from "./auth";

const PAGE_SIZE = 20;

type NotificationContextProps = {
  children: ReactNode;
};
interface IInitialValues {
  hasNotifications: boolean;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<
    QueryObserverResult<InfiniteData<INotificationViewModel[]>, unknown>
  >;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<INotificationViewModel[], unknown>>;
  notifications: INotificationViewModel[];
  readAll: (callback?: () => void) => void;
  removeAll: (callback?: () => void) => void;
  read: (id: string, callback?: () => void) => void;
  remove: (id: string, callback?: () => void) => void;
}

const NotificationContext = React.createContext({} as IInitialValues);

const NotificationContextProvider = ({
  children,
}: NotificationContextProps) => {
  const confirm = useAlert();
  const { user, logged } = useAuth();

  const [notifications, setNotifications] = React.useState<
    INotificationViewModel[]
  >([]);

  const {
    data: results,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["notifications"],
    ({ pageParam = 1 }) =>
      NotificationController.getAll(pageParam, PAGE_SIZE).then(
        (res) => res.data
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!user?.id && logged,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    }
  );

  useEffect(() => {
    setNotifications(results?.pages?.flat() ?? []);
  }, [results]);

  const hasNotifications = useMemo(
    () => !!notifications.filter((n) => !n.readed).length,
    [notifications]
  );

  useWebSocket<INotificationViewModel>({
    url: `notification/${user?.id}`,
    onMessage: (notification) => {
      setNotifications((prev) => [notification, ...prev]);
    },
  });

  useWebSocket<void>({
    url: `notification/${user?.id}/markAllAsRead`,
    onMessage: () => {
      setNotifications([
        ...notifications.map((n) => {
          return { ...n, readed: true };
        }),
      ]);
    },
  });

  useWebSocket<string>({
    url: `notification/${user?.id}/markAsRead`,
    onMessage: (notificationId) => {
      setNotifications((prev) => {
        const newNotifications = [...prev];
        const index = newNotifications.findIndex(
          (s) => s.id === notificationId
        );
        if (index !== -1) {
          newNotifications[index].readed = true;
        }
        return newNotifications;
      });
    },
  });

  useWebSocket<string>({
    url: `notification/${user?.id}/delete`,
    onMessage: (id) =>
      setNotifications((prev) => prev.filter((n) => n.id !== id)),
  });

  useWebSocket<void>({
    url: `notification/${user?.id}/deleteAll`,
    onMessage: () => setNotifications([]),
  });

  const readAll = (callback?: () => void) => {
    NotificationController.markAllAsRead()
      .catch(
        async () =>
          await confirm.show({
            type: "error",
            title: "Falha",
            timeout: 5000,
            description:
              "Algum erro inesperado aconteceu ao marcar notificações como lidas.\nTente novamente...",
            retry: {
              callback: () => readAll(callback),
            },
            cancel: {
              label: "Ok",
            },
          })
      )
      .finally(callback);
  };

  const read = (id: string, callback?: () => void) => {
    NotificationController.markAsRead(id)
      .catch(
        async () =>
          await confirm.show({
            type: "error",
            title: "Falha",
            timeout: 5000,
            description:
              "Algum erro inesperado aconteceu ao marcar notificação como lida.\nTente novamente...",
            retry: {
              callback: () => read(id, callback),
            },
            cancel: {
              label: "Ok",
            },
          })
      )
      .finally(callback);
  };

  const removeAll = (callback?: () => void) => {
    NotificationController.removeAll()
      .catch(
        async () =>
          await confirm.show({
            type: "error",
            title: "Falha",
            timeout: 5000,
            description:
              "Algum erro inesperado aconteceu ao excluir notificações.\nTente novamente...",
            retry: {
              callback: () => removeAll(callback),
            },
            cancel: {
              label: "Ok",
            },
          })
      )
      .finally(callback);
  };

  const remove = (id: string, callback?: () => void) => {
    NotificationController.remove(id)
      .catch(
        async () =>
          await confirm.show({
            type: "error",
            title: "Falha",
            timeout: 5000,
            description:
              "Algum erro inesperado aconteceu ao excluir a notificação.\nTente novamente...",
            retry: {
              callback: () => remove(id, callback),
            },
            cancel: {
              label: "Ok",
            },
          })
      )
      .finally(callback);
  };

  return (
    <NotificationContext.Provider
      value={{
        read,
        remove,
        readAll,
        refetch,
        removeAll,
        isLoading,
        hasNextPage,
        fetchNextPage,
        notifications,
        hasNotifications,
        isFetchingNextPage,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
export const useNotification = () => useContext(NotificationContext);
export default NotificationContextProvider;
