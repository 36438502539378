import { AppBar } from "components"
import { Button, Container, Content, SearchContainer, SideBar } from "./style"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography, Button as MuiButton, Autocomplete } from "@mui/material"
import { Delete, Info, LocationSearching, Photo, PictureAsPdf, Search, Sync } from "@mui/icons-material"
import { useTheme } from "styled-components"
import { useEffect, useMemo, useState } from "react"
import { ReactFlowMap } from "components/ReactFlowMap"
import { useForm, Controller } from "react-hook-form"
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { IRequestDocumentRelationMapsInputModel } from "inputModels"
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { MASK_CNPJ, MASK_CPF } from "../../constants";
import { toPattern } from "vanilla-masker"
import { useApp } from "contexts"
import { getByCnpj } from "services/consultCnpj"
import { useRelationMap } from "contexts/relationMap"
import { PrivateIndividualInformationBaseController } from "controllers/PrivateIndividualInformationBaseController"

export const RelationMap = () => {
  const theme = useTheme()
  const [mask, setMask] = useState(MASK_CNPJ);
  const [isDialogOpen, setIsDialogOpen] = useState(false) 
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false) 
  const [isRefreshDialogOpen, setIsRefreshDialogOpen] = useState(false) 
  const [islocationOpen, setIslocationOpen] = useState(false) 

  const {
    company,
    setCompany,
    setPrivateIndividual,
    privateIndividual,
    refreshNodes,
    searchoptions,
    location,
    setImageName,
  } = useRelationMap()

  const [defaultValues] = useState<IRequestDocumentRelationMapsInputModel>({
    document: ''
  });

  const schema = yup.object().shape({
    document: yup
    .string()
    .required('documento obrigatório')
    .test("isRequired", "Digite um documento válido", (value) => cnpj.isValid(value) || cpf.isValid(value))
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
    resolver: yupResolver(schema)
  });

  const { document } = watch()

  useEffect(()=>{setMask(document.length > 14 ? MASK_CNPJ : MASK_CPF)},[document])

  const { setLoading ,setIsExitConfirm } = useApp()

  useEffect(() => {
    setIsExitConfirm(company !== null || privateIndividual !== null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company,privateIndividual]);

  const onSubmit = ({ document: doc }: IRequestDocumentRelationMapsInputModel) => {
    if(cnpj.isValid(doc)){
      setLoading(true)
      getByCnpj(doc.replace(/[^\d]/g, ''))
        .then(setCompany)
        .catch(()=>{
          setIsDialogOpen(true)
        })
        .finally(()=>setLoading(false))
    }
    if(cpf.isValid(doc)){
      setLoading(true)
      PrivateIndividualInformationBaseController.GetPrivateIndividual(doc)
        .then((response) => {
          if(response.status){
            setPrivateIndividual(response)
          }
          else{
            setIsDialogOpen(true)
          }
        })
        .catch(()=>{
          setIsDialogOpen(true)
        })
        .finally(()=>setLoading(false))
    }
  }

  return(
    <Container>
      <AppBar />
      <SideBar data-testid='sideBarMap'>
        <header>
          <Typography component="p" variant="h5" color="primary">
            Mapa de relações
          </Typography>
        </header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller 
            control={control}
            name="document"
            render={({field: {value, onChange}})=>
              <TextField
                fullWidth
                size="small"
                label="Documento"
                error={!!errors.document?.message}
                helperText={errors.document?.message}
                disabled={company !== null || privateIndividual !== null}
                onChange={onChange}
                value={mask ? toPattern(value ?? '', mask) : mask}
              />
            }
          />
          <IconButton type="submit" color="primary" disabled={company != null || privateIndividual !== null}><Search /></IconButton>
        </form>
        {(company !== null || privateIndividual !== null) &&
          <>
            <header>
              <Typography component="p" variant="h6" color="primary">
                Opções
              </Typography>
            </header>
            <div className="collum">
              <Button data-testid='buttonLocation' onClick={()=>setIslocationOpen(!islocationOpen)} active={islocationOpen}><LocationSearching/> Localizar</Button>
              <Button onClick={()=>{setImageName(`${company?.companyName ?? privateIndividual?.name} Mapa.png`)}}><Photo/> Salvar Imagem</Button>
              <Button onClick={()=>setIsRefreshDialogOpen(true)} ><Sync/> Refazer mapa</Button>
              <Button warning onClick={() => setIsDeleteDialogOpen(true)}><Delete/> Deletar mapa</Button>
            </div>
          </>
        }

      </SideBar>
      <Content>
        {(company !== null || privateIndividual !== null) ?
          <ReactFlowMap />
        :
          <Typography component="p" variant="h3" color={()=>theme.colors.grey}>
            Digite um CPF/CNPJ para iniciar
          </Typography>
        }
      </Content>
      {islocationOpen &&
        <SearchContainer>
          <Autocomplete onChange={(_,value) => location(value ?? '')} options={searchoptions} renderInput={(params) => <TextField {...params} label="Localizar" />}/>
        </SearchContainer>
      }

      <Dialog
        open={isDialogOpen}
        onClose={()=>setIsDialogOpen(!isDialogOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{display: 'flex', alignItems: 'center', gap: 2}} id="alert-dialog-title">
          <Info/> Aviso
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Não foi possível consultar seu documento, tente novamente mais tarde
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={()=>setIsDialogOpen(!isDialogOpen)}>
            Confirmar
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isRefreshDialogOpen}
        onClose={()=>setIsRefreshDialogOpen(!isRefreshDialogOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{display: 'flex', alignItems: 'center', gap: 2}} id="alert-dialog-title">
          <Info/> Refazer Nodes
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja mesmo refazer todos os nodes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton color="error" onClick={()=>{
            setIsRefreshDialogOpen(!isRefreshDialogOpen)}}>
            Negar
          </MuiButton>
          <MuiButton onClick={()=>{
            setIsRefreshDialogOpen(!isRefreshDialogOpen)
            refreshNodes()
          }}>
            Confirmar
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={()=>setIsDeleteDialogOpen(!isDeleteDialogOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{display: 'flex', alignItems: 'center', gap: 2}} id="alert-dialog-title">
          <Info/> Deletar
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja mesmo deletar todos os nodes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton color="error" onClick={()=>{
            setIsDeleteDialogOpen(!isDeleteDialogOpen)}}>
            Negar
          </MuiButton>
          <MuiButton onClick={()=>{
            setIsDeleteDialogOpen(!isDeleteDialogOpen)
            refreshNodes()
            setCompany(null)
            setPrivateIndividual(null)
            setValue('document', '')
            setIslocationOpen(false)
          }}>
            Confirmar
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
