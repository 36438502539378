import {
  Download,
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
  Search,
  Sort,
  Visibility,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Collapse,
  TextField,
} from "@mui/material";
import { AppBar } from "components";
import { EXTERNAL_FONT_NAMES } from "../../constants";
import { useLogUser } from "contexts";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { LogStatus, LogStatusText } from "types/enums";
import { Cnpj, Cpf } from "utils/masks";
import { ContentTable } from "./styles";
import { Columns } from "props";
import { Content } from "pages/LogsUser/styles";
import { exportReportFinancialFonts } from "controllers/ReportController";
import { Controller, useForm } from "react-hook-form";

const FinancialReport = () => {
  const {
    reportsFinancials,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    orderBy,
    setOrder,
    handleFetchNextPage,
    isLoadingReportFinancial,
  } = useLogUser();
  const [reportFinancialFiltered, setReportFinancialFiltered] =
    useState(reportsFinancials);
  const [openLogs, setOpenLogs] = useState<{ [key: string]: boolean }>({});
  const [defaultValues] = useState({ filter: "" });
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const { control, watch } = useForm({
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });

  const theme = useTheme();
  const data = watch();

  const handleSort = (key: Columns) => () => {
    setOrder((prev) => {
      const newOrder = [...prev];
      const oldOrderIdx = prev.findIndex((o) => o.key === key);
      if (oldOrderIdx === -1) {
        newOrder.push({
          key,
          direction: "asc",
        });
      } else if (newOrder[oldOrderIdx].direction === "asc") {
        newOrder[oldOrderIdx].direction = "desc";
      } else {
        newOrder.splice(oldOrderIdx, 1);
      }

      return newOrder;
    });
  };

  const getSortIcon = (key: Columns) => (
    <IconButton
      data-testid="sortIconButton"
      size="small"
      onClick={handleSort(key)}
    >
      {orderBy.find((o) => o.key === key) ? (
        <KeyboardArrowDown
          sx={{
            transition: "all 0.5s ease-in",
            transform:
              orderBy.find((o) => o.key === key)?.direction === "asc"
                ? "rotate(-180deg)"
                : "",
          }}
        />
      ) : (
        <Sort />
      )}
    </IconButton>
  );

  const onRequestExtract = async () => {
    setIsLoadingExport(true);
    try {
      await exportReportFinancialFonts();
    } finally {
      setIsLoadingExport(false);
    }
  };

  useEffect(() => {
    setReportFinancialFiltered(reportsFinancials);
  }, [reportsFinancials]);

  return (
    <>
      <AppBar />
      <Container
        sx={{
          minWidth: "90vw",
          minHeight: "100vh",
          pt: 10,
          pb: 4,
        }}
      >
        <ContentTable style={{ maxHeight: "86vh" }}>
          <Typography
            style={{ marginTop: "3rem" }}
            component="p"
            variant="h4"
            color="primary"
          >
            Relatório Financeiro
          </Typography>
          <Typography
            className="containerExtractReport"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={onRequestExtract}
              startIcon={
                isLoadingExport ? <CircularProgress size={24} /> : <Download />
              }
              disabled={isLoadingExport}
            >
              {isLoadingExport ? "Exportando..." : "Exportar Relatório"}
            </Button>
          </Typography>
          <Grid display="flex" justifyContent="end" margin="0.5rem 0">
            <Controller
              control={control}
              name="filter"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar"
                  variant="standard"
                  type="search"
                  autoComplete="off"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 250 }}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    sx: { pl: 1 },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
          </Grid>
          <Table stickyHeader aria-label="listDossiers">
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    backgroundColor: "white",
                    "& p": {
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    },
                    "& div": {
                      display: "flex",
                      alignItems: "center",
                      "& button": { ml: 1 },
                    },
                  },
                }}
              >
                <TableCell>
                  <Typography>#</Typography>
                </TableCell>
                <TableCell>
                  <Grid data-testid="gridDate">
                    <Typography>Data</Typography>
                    {getSortIcon("date")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Usuário</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Empresa</Typography>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>CPF/CNPJ</Typography>
                    {getSortIcon("document")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Fontes</Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading || isLoadingReportFinancial ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Grid
                      item
                      alignItems="center"
                      sx={{ minHeight: "65px !important" }}
                    >
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando lista de relatórios...
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : !reportFinancialFiltered.length ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Nenhum relatório encontrado...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                reportFinancialFiltered
                  .filter((m) =>
                    data.filter
                      ? m.nameUser
                          ?.toLowerCase()
                          .includes(data.filter.toLowerCase()) ||
                        m.name
                          ?.toLocaleLowerCase()
                          .includes(data.filter.toLocaleLowerCase()) ||
                        new Date(m.createdAt)
                          .toLocaleDateString("pt-BR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                          .includes(data.filter) ||
                        m.dossierDocument?.includes(data.filter)
                      : reportFinancialFiltered
                  )
                  .map(({ id, ...log }, idx) => (
                    <>
                      <TableRow data-testid="rowFinancial"
                        key={id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography color="primary">
                            {(idx + 1).toString().padStart(2, "000")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {moment(log.createdAt).format("DD/MM/yyyy HH:mm")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {log.nameUser} {log.name ? `/ ${log.name}` : ""}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{log.name ? log.name : "-"}</Typography>
                        </TableCell>
                        <TableCell data-testid="tableCellDocument">
                          <Typography>
                            {log.dossierDocument
                              ? log.dossierDocument.length === 11
                                ? Cpf(log.dossierDocument)
                                : Cnpj(log.dossierDocument)
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setOpenLogs((prevOpenLogs) => ({
                                ...prevOpenLogs,
                                [id]: !prevOpenLogs[id],
                              }));
                            }}
                          >
                            {openLogs[id] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={openLogs[id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ margin: 1.5 }}>
                              <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                color={theme.palette.primary.main}
                              >
                                Fontes
                              </Typography>
                              <Container data-testid='containerFonts'>
                                {Object.keys(log.dossier)
                                  .sort((a, b) => a.localeCompare(b))
                                  .map((key) => (
                                    <Content data-testid='contentFonts' key={key}>
                                      <Typography variant="h6" fontWeight={400}>
                                        {EXTERNAL_FONT_NAMES[key]}
                                      </Typography>
                                      <Typography
                                        variant="h6"
                                        fontWeight={400}
                                        color={
                                          log.dossier[
                                            key as keyof typeof log.dossier
                                          ].status === LogStatus.RESOLVED
                                            ? "green"
                                            : log.dossier[
                                                key as keyof typeof log.dossier
                                              ].status === LogStatus.UNSOLVED
                                            ? "error"
                                            : "info"
                                        }
                                      >
                                        {
                                          LogStatusText[
                                            log.dossier[
                                              key as keyof typeof log.dossier
                                            ].status
                                          ]
                                        }
                                      </Typography>
                                    </Content>
                                  ))}
                              </Container>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
              )}
              {hasNextPage && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {isFetchingNextPage ? (
                      <Grid
                        item
                        alignItems="center"
                        sx={{ minHeight: "65px !important" }}
                      >
                        <CircularProgress size={15} />
                        <Typography
                          paddingLeft={1}
                          variant="caption"
                          color="text"
                          sx={{ fontStyle: "italic" }}
                        >
                          Carregando itens...
                        </Typography>
                      </Grid>
                    ) : (
                      <Button data-testid="buttonNextPage"
                        onClick={() => handleFetchNextPage()}
                        sx={{ px: 2 }}
                      >
                        <KeyboardDoubleArrowDown />
                        <Typography paddingLeft={2} variant="button">
                          Carregar mais...
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ContentTable>
      </Container>
    </>
  );
};

export default FinancialReport;
