import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .react-flow__node		{
    border: none;
  }
  .react-flow__node.selected		{
    border: none;
  }
  .react-flow__handle	{
    opacity: 0%;
  }
  

`
