import { useEffect, useRef, useState } from "react"
import { NodeContainer, NodeContent, NodeOptions } from "./style"
import { Add, CommentsDisabled, CopyAllSharp, Delete, Message, Sync } from "@mui/icons-material"
import { useRelationMap } from "contexts/relationMap"
import { INodeElementOptions } from "types/props"
import { Handle, Position } from "reactflow"
import { TextareaAutosize } from "@mui/material"

interface nodeElementProps {
  element: INodeElementOptions
  root: boolean,
}

export const NodeElement = ({
  element,
  root
}: nodeElementProps) => {

  const [isOpen, setIsOpen] = useState(false)
  const [isMessage, setIsMessage] = useState(false)
  const [length, setLenght] = useState(0)
  const [copying, setCopying] = useState(false);

  useEffect(() => {
    setLenght((element.value?.length ?? 0) > element.label.length ? (element.value?.length ?? 0) : element.label.length)
  }, [element])

  const copyText = () => {
    const text = (element.value ?? '') !== ''? (element.value ?? '') : element.label
    setCopying(true);
    navigator.clipboard.writeText(text)
    setTimeout(() => setCopying(false), 400);
  }

  const {
    createNode,
    deleteNode,
    refreshNodes
  } = useRelationMap()



  return (
    <NodeContainer>
      <NodeContent maxLenght={length} onClick={()=>setIsOpen(!isOpen)}>
        <h1>{element.label}</h1>
        {element.value !== '' && <span>{element.value}</span>}
        {isMessage &&
          <TextareaAutosize style={{
            outline: 0,
            resize: "none"
          }}
          placeholder="Anotação"
          />
        }
      </NodeContent>
      {isOpen &&
        <NodeOptions>
          {element.value !== undefined && 
            <button key={'copy'} className={copying ? 'copying' : ''} onClick={()=>copyText()}>
              <CopyAllSharp sx={{width: 13,height: 13}}/> {copying ? 'Copiado!' : 'Copiar Texto'}
            </button>
          }

          <button key={'note'} onClick={()=>setIsMessage(!isMessage)}>
            {isMessage? <CommentsDisabled sx={{width: 13,height: 13}}/>  : <Message sx={{width: 13,height: 13}}/> }
            {isMessage? 'retirar anotação' : 'Anotação'}
          </button>
          {root 
            ? <button key={'delete1'} className="delete" onClick={() => refreshNodes()}><Sync sx={{width: 13,height: 13}}/> Refazer mapa</button>
            : <button key={'delete2'} className="delete" onClick={() => deleteNode(element)}><Delete sx={{width: 13,height: 13}}/> Deletar</button>
          }
          {element.children && element.children.filter(o => !o.isOpen && o.value !== undefined).map((o, index) =>
            <button key={`${index}-${element.label}`} onClick={()=>{createNode(o, element)}}>
              <Add sx={{width: 13,height: 13}}/> {o.label}
            </button>
          )}
        </NodeOptions>
      }
      <Handle id="top" type={'source'} position={Position.Top} />
      <Handle id="top" type={'target'} position={Position.Top} />
      <Handle id="left" type={'source'} position={Position.Left} />
      <Handle id="left" type={'target'} position={Position.Left} />
      <Handle id="bottom" type={'source'} position={Position.Bottom} />
      <Handle id="bottom" type={'target'} position={Position.Bottom} />
      <Handle id="right" type={'target'} position={Position.Right} />
      <Handle id="right" type={'source'} position={Position.Right} />
    </NodeContainer>
  )
}