import { Cancel } from "@mui/icons-material";
import { Button, CircularProgress, Container, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Modal } from "components";
import { ModalProps } from "..";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { EmailController } from "controllers/EmailController";
import { IRecoverPasswordInputModel, IUpdatePasswordCollaboratorUserInputModel } from "inputModels";
import { CollaboratorUserController } from "controllers/CollaboratorUserController";
import { useAlert } from "contexts";
import { usePersistedState } from "hooks";
import { encryptPassword } from "utils/encryptPassword";
import { AuthController } from "controllers";
import { decryptCode } from "utils/crypto";
import { Input } from "components/Input";
interface IData {
  contactValue: string;
  contactLabel: string;
  codeExpected: string;
}
interface RecoverPasswordModalProps extends ModalProps {
  data: IData;
  email?: string;
  typeModal: "changePassword" | "recoverPassword";
  onCallback?: () => void;
  onCodeChange: (newCode: string) => void;
  collaboratorUserId?: string;
}

const RecoverPasswordModal: React.FC<RecoverPasswordModalProps> = ({
  onCallback,
  onCodeChange,
  typeModal,
  email,
  data,
  collaboratorUserId,
  ...props
}: RecoverPasswordModalProps) => {
  const TIMER = 60000;
  const alert = useAlert();
  const [dateToSend, setDateToSend] = usePersistedState<number | undefined>('dateToSend', undefined);
  const [disableResend, setDisableResend] = useState(
    dateToSend === undefined ? false : (dateToSend + TIMER) > new Date().getTime(),
  );
  const [secondsRemainingToResend, setSecondsRemainingToResend] = useState(TIMER / 1000);
  const [requesting, setRequesting] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [defaultValues] = useState({
    code: '',
    email: email,
    password: "",
    confirmPassword: "",
  });
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: defaultValues
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (dateToSend !== undefined) {
      interval = setInterval(() => {
        const isDisabled = dateToSend + TIMER > new Date().getTime();
        setDisableResend(isDisabled);
        setSecondsRemainingToResend((prev) => prev - 1);
        if (!isDisabled) {
          setSecondsRemainingToResend(TIMER / 1000);
          setDateToSend(undefined);
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [dateToSend, setDateToSend]);

  const code = watch("code");
  const emailValue = watch("email");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const handleOnCallback = () => {
    if (code === '') {
      setError('code', {
        message: 'Insira o código de verificação',
      });
    }
    if (data.codeExpected !== code) {
      setError('code', {
        message: 'Código não corresponde',
      });
    }
    if (typeModal === 'changePassword' && data.codeExpected === code && code !== '') {
      handleUpdatePasswordCollaboratorUser();
    }
    if (typeModal === 'recoverPassword' && data.codeExpected === code && code !== '') {
      handleRecoverPassword();
    }
  };

  const handleRecoverPassword = async () => {
    setRequesting(true);

    let hasError = false;

    if (password.length < 6) {
      setError("password", { message: "A nova senha deve conter no mínimo 6 caracteres" });
      hasError = true;
    }
    if (confirmPassword !== password) {
      setError("confirmPassword", { message: "As senhas não coincidem" });
      hasError = true;
    }
    if (hasError) {
      setRequesting(false);
      return;
    }
    if (!emailValue) return;

    const update: IRecoverPasswordInputModel = {
      email: emailValue,
      password: encryptPassword(password),
    };
    await AuthController.recoverPassword(update).then(() => {
      props?.onClose();
      alert.show({
        type: "alert",
        title: "Sucesso:",
        description: "Senha alterada com sucesso.",
        timeout: 3000,
      });
    }).finally(() => {
      clearErrors();
      setValue('code', '');
      setRequesting(false);
    });
  };

  const handleUpdatePasswordCollaboratorUser = async () => {
    setRequesting(true);
    let hasError = false;

    if (password.length < 6) {
      setError("password", { message: "A nova senha deve conter no mínimo 6 caracteres" });
      hasError = true;
    }
    if (confirmPassword !== password) {
      setError("confirmPassword", { message: "As senhas não coincidem" });
      hasError = true;
    }
    if (hasError) {
      setRequesting(false);
      return;
    }

    if (!collaboratorUserId) return;

    const update: IUpdatePasswordCollaboratorUserInputModel = {
      id: collaboratorUserId,
      password: encryptPassword(password),
    };

    CollaboratorUserController.updatePasswordUser(update).then(() => {
      setRequesting(false);
      props?.onClose();
    }).finally(() => {
      setValue('code', '');
      setRequesting(false);
    });
  };

  const handleSendConfirmationEmail = async () => {
    setDateToSend(new Date().getTime());
    if (emailValue) {
      EmailController.sendCodeVerification({
        to: emailValue,
        subject: "Redefinição de senha",
        description: "Utilize o código abaixo para redefinir sua senha no Legal Control Audit:",
      }).then((resCode) => {
        onCodeChange(decryptCode(resCode));
        setDisabled(false);
      });
    }
  };

  return (
    <Modal
      {...props}
      sx={{
        position: "relative",
        width: "24rem",
        height: "34rem",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <IconButton
        size="small"
        onClick={props?.onClose}
        color="error"
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Cancel />
      </IconButton>

      <Container >
        <Grid>
          <Typography variant="h5" color="primary" marginBottom={5}>Recuperar Senha</Typography>
          <Grid marginBottom={2}>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value, ...rest } }) =>
                <TextField
                  disabled={typeModal === 'changePassword'}
                  fullWidth
                  onChange={onChange}
                  value={value ?? ''}
                  size="small"
                  label="E-mail"
                  {...rest}
                />
              }
            />
          </Grid>
          <Grid display="flex" justifyContent="center" marginBottom={2}>
            <Button
              style={{ width: "12rem" }}
              disabled={disableResend}
              type="submit"
              variant="contained"
              sx={{ cursor: requesting ? "wait" : "pointer" }}
              onClick={() => {
                handleSendConfirmationEmail();
              }}
            >
              <Typography variant="button">{disableResend ? `Enviar código (${secondsRemainingToResend})` : 'Enviar código'}</Typography>
            </Button>
          </Grid>
          <Grid marginBottom={2}>
            <Controller
              name="code"
              control={control}
              render={({ field: { onChange, value, ...rest } }) =>
                <TextField
                  fullWidth
                  disabled={disabled}
                  onChange={onChange}
                  value={value}
                  size="small"
                  label="Código de Verificação"
                  helperText={errors.code?.message}
                  error={!!errors.code}
                  placeholder="Insira o código de verificação"
                  {...rest}
                />
              }
            />
          </Grid>
          <Grid marginBottom={2}>
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, value, ...rest } }) =>
                <Input
                  // fullWidth
                  disabled={disabled}
                  control = {control}
                  onChange={onChange}
                  // error={!!errors.password?.message}
                  // helperText={errors.password?.message}
                  type="password"
                  // size="small"
                  // label="Nova Senha"
                  {...rest}
                />
              }
            />
          </Grid>
          <Grid marginBottom={3}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field: { onChange, value, ...rest } }) =>
                <Input
                  // fullWidth
                  disabled={disabled}
                  control = {control}
                  onChange={onChange}
                  // error={!!errors.confirmPassword?.message}
                  // helperText={errors.confirmPassword?.message}
                  type="password"
                  // size="small"
                  // label="Confirmar Senha"
                  {...rest}
                />
              }
            />
          </Grid>
          <Grid display="flex" justifyContent="center" marginTop="3rem">
            <Button
              style={{ width: "10rem" }}
              type="button"
              variant="contained"
              sx={{ cursor: requesting ? "wait" : "pointer" }}
              onClick={handleSubmit(handleOnCallback)}
            >
              <Typography variant="button">Salvar</Typography>
              {requesting && (
                <CircularProgress
                  size={15}
                  sx={{ ml: 1, color: "white" }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Modal >
  );
};

export default RecoverPasswordModal;