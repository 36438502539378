import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4.8rem;
  height: calc(100vh - 4.8rem);
  align-items: center;
  justify-content: center;
`



export const SideBar = styled.div`
  position: absolute;
  z-index: 200;
  background-color: ${({ theme: {colors: {background_white}} })=>background_white};
  padding: 1rem;
  height: calc(90vh - 4.8rem);
  width: 18%;
  min-width: 18rem;
  top: calc(100vh - 90vh);
  left: 2rem;
  border-radius: 0.4rem;
  box-shadow: 0 0 5px #00000046;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  header{
    width: 100%;
  }
  form{
    width: 90%;
    display: flex;
    gap: 0.5rem;
    height: 2.6rem;
  }
  .collum{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    & *:hover{
      margin-left: 0.4rem;
    }
  }
  @media (max-width: 1200px) {
    left: 1rem;
    height: fit-content;
  }
`

export const Button = styled.button<{warning?: boolean, active?: boolean}>`
  width: 100%;
  padding: 1rem 2rem;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: flex-start;
  color: ${({warning, active, theme: {colors: {primary, red, text_grey}}}) => warning === true? red : active === false? text_grey : primary};
  &:hover{
    background-color: #aacff11e;
    transition: 300ms ease-in-out;
    border-radius: 4px;
  }
`

export const SearchContainer = styled.div`
  position: fixed;
  top: calc(100vh - 90vh);
  left: calc(18% + 5rem);
  background-color: ${({theme: {colors: {background_white}}}) => background_white};
  padding: 1rem;
  border-radius: 0.4rem;
  box-shadow: 0 0 5px #00000046;
  width: 50%;
  z-index: 300;
  @media (max-width: 1200px) {
    left: 20rem;
  }
  @media (max-width: 580px) {
    top: calc(100vh - 88vh);
    left: 2rem;
    width: 15rem;
  }
`