import { Cancel } from "@mui/icons-material";
import { Button, CircularProgress, Container, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Modal } from "components";
import { ModalProps } from "..";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { EmailController } from "controllers/EmailController";
import { usePersistedState } from "hooks";
import { decryptCode } from "utils/crypto";


interface IData {
  contactValue: string;
  contactLabel: string;
  codeExpected: string;
}

interface SendConfirmationModalProps extends ModalProps {
  data: IData;
  onCallback: () => void;
  onCodeChange: (newCode: string) => void;
}

const SendConfirmationModal: React.FC<SendConfirmationModalProps> = ({
  onCallback,
  onCodeChange,
  data,
  ...props
}: SendConfirmationModalProps) => {
  const TIMER = 60000;
  const [requesting] = useState(false);
  const [dateToSend, setDateToSend] = usePersistedState<number | undefined>('dateToSend', undefined);
  const [disableResend, setDisableResend] = useState(
    dateToSend === undefined ? false : (dateToSend + TIMER) > new Date().getTime(),
  );
  const [secondsRemainingToResend, setSecondsRemainingToResend] = useState(TIMER / 1000);
  const [defaultValues] = useState({
    code: '',
  });
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (dateToSend !== undefined) {
      interval = setInterval(() => {
        const isDisabled = dateToSend + TIMER > new Date().getTime();
        setDisableResend(isDisabled);
        setSecondsRemainingToResend((prev) => prev - 1);
        if (!isDisabled) {
          setSecondsRemainingToResend(TIMER / 1000);
          setDateToSend(undefined);
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [dateToSend, setDateToSend]);

  const code = watch("code");

  const handleOnCallback = () => {
    if (data.codeExpected === code && code !== '') {
      onCallback();
      setValue('code', '');
    } else {
      setError('code', {
        message: 'Código não corresponde',
      });
    }
  };

  const handleResendCodOnCallback = () => {
    setDateToSend(new Date().getTime());
    EmailController.sendCodeVerification({
      to: data.contactValue,
      subject: "Ativação de conta",
      description: "Utilize o código abaixo para ativar sua conta no Legal Control Audit:",
    }).then((resCode) => {
      onCodeChange(decryptCode(resCode));
    });
  };

  return (
    <Modal
      {...props}
      sx={{
        position: "relative",
        width: "24rem",
        height: "34rem",
        overflowX: "hidden",
        overflowY: "auto",
        p: 1,
      }}
    >
      <IconButton
        size="small"
        onClick={props?.onClose}
        color="error"
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Cancel />
      </IconButton>

      <Container >
        <Grid>
          <Typography variant="h5" color="primary" marginBottom={4} marginTop={2}>Verificação de E-mail</Typography>
          <Typography variant="h5" fontSize="1.3rem" color="primary" marginBottom={3}>Enviamos um código de verificação para o e-mail: {data.contactValue}</Typography>
          <Grid marginBottom={3}>
            <Controller
              name="code"
              control={control}
              render={({ field: { onChange, value, ...rest } }) =>
                <TextField
                  fullWidth
                  onChange={onChange}
                  size="small"
                  label="Código de verificação"
                  helperText={errors.code?.message}
                  error={!!errors.code}
                  placeholder="Insira o código de verificação"
                  {...rest}
                />
              }
            />
          </Grid>
          <Grid display="flex" alignItems="center" gap="0.3rem" marginTop="12rem">
            <Button
              style={{ width: "11rem" }}
              disabled={disableResend || code === ''}
              type="button"
              variant="contained"
              sx={{ cursor: requesting ? "wait" : "pointer" }}
              onClick={handleSubmit(handleResendCodOnCallback)}
            >
              <Typography variant="button">{disableResend ? `Reenviar código (${secondsRemainingToResend})` : 'Reenviar código'}</Typography>
            </Button>
            <Grid>
              <Button
                disabled={code === ''}
                style={{ width: "09rem" }}
                type="button"
                onClick={handleSubmit(handleOnCallback)}
                variant="contained"
                sx={{ cursor: requesting ? "wait" : "pointer" }}
              >
                <Typography variant="button">
                  Verificar
                </Typography>
                {requesting && (
                  <CircularProgress
                    size={15}
                    sx={{ ml: 1, color: "white" }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Modal >
  );
};

export default SendConfirmationModal;

